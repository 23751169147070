@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.change-password-module-view {
  .content {
    .heading {
      font-size: $default-font-size * 3;
      letter-spacing: -0.5px;
      padding-bottom: $default-padding * 5;
      @media screen and (max-width: 1024px){
        font-size: $default-font-size + 15;
        padding-bottom: $default-padding * 3;
      }
    }
    .btn-wrapper {
      padding-top: $default-padding * 5;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1024px){
        padding-top: $default-padding * 3;
      }
      .submit-button {
        width: 130px;
        height: 50px;
        border-radius: 4px;
        color: #ffffff;
        background-color: #2f3d51;
        border: solid 1px #2f3d51;
      }
    }
  }
}