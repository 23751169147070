@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.list-of-topics-heading {
  font-size: $default-font-size*3;
  padding-top: $default-padding*5;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #f26221;
  @media screen and (max-width: 1024px){
    font-size: $default-font-size * 2;
  }
}
.list-of-topics-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .print-link {
    margin: 0px;
  }
}
.list-of-topics-container {
  background-color: #fbfbfb;
}
.list-of-topics-wrapper {
  text-align: center;
  padding: $default-padding*5 0px $default-padding*30 0px;
  .topic-item {
    padding: 0px $default-padding*3;
    margin: auto;
    margin-bottom: 30px;
    box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    .topic-list-number {
      font-size: $default-font-size * 3;
      font-weight: bold;
      color: #3d3d3d;
    }
    .topic-info {
      padding: $default-padding+5 $default-padding*3;
      .topic-name{
        font-weight: 500;
        font-size: $default-font-size+8;
        width: 100%;
        word-break: break-all;
        text-align: left;
        padding: 5px 0px;
        letter-spacing: -0.3px;
        color: #3d3d3d;
      }
      .topic-description-info {
        font-size: $default-font-size+8;
        line-height: 1.83;
        color: #686868;
        float: left;
        text-align: left;
      }
    }
    .topic-category {
      color: #686868;
      font-size: $default-font-size + 2;
      text-align: right;
    }
  }
}