@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.landing-page-view{
  @mixin section-heading{
    max-width: 350px;
    padding-top: $default-padding*2;
    @include set-font(QuickSandMedium, $default-padding*2, $mardi-gras, normal, left, normal);
    @media screen and (min-width: 1024px){
      max-width: 560px;
      font-size: ($default-font-size*2)+6;
    }
  }
  .hero-banner-wrapper{
    position: relative;
    .hero-banner{
      width: 100%;
      height: 300px;
      position: relative;
      @media screen and (min-width: 1024px){
        height: 500px;
      }
    }
    .banner-content-wrapper{
      max-width: 400px;
      position: absolute;
      text-align: left;
      padding-top: $default-padding*2;
      color: white;
      z-index: 10;
      @media screen and (min-width: 1024px){
        padding-top: ($default-padding*7)+7;
        max-width: 600px;
      }
      .banner-heading{
        padding-bottom: $default-padding*2;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*2;
        }
        .small-heading{
          @include set-font(QuickSandMedium, $default-font-size+6, $white, normal, left, normal);
          margin-bottom: $default-margin - 5;
          @media screen and (min-width: 1024px){
            font-size: ($default-font-size*2) + 6;
            margin-bottom: $default-margin;
          }
        }
        .big-heading{
          text-transform: uppercase;
          @include set-font(QuickSandBold, $default-font-size*2, $white, normal, left, normal);
          @media screen and (min-width: 1024px){
            font-size: $default-font-size*4;
          }
        }
      }
      .banner-content-para{
        @include set-font(QuickSandLight, $default-font-size+4, $white, $default-line-height*2, left, normal);
        @media screen and (min-width: 1024px){
          @include set-font(QuickSandLight, $default-font-size+8, $white, $default-line-height*3, left, normal);
        }
      }
      .contact-us-btn-wrapper{
        margin-top: $default-margin*2;
        @media screen and (min-width: 1024px){
          margin-top: $default-margin*7;
        }
        .contact-us-btn{
          position: absolute;
          width: 160px;
          border-radius: 100px;
          background-color: $white;
          box-shadow: 0 28px 43px 0 rgba(0, 0, 0, 0.11);
          padding: $default-font-size $default-font-size*2;
          @include align-item(flex, space-between, center, row);
          .btn-text{
            @include set-font(QuickSandBold, $default-font-size+4, $mako, normal, left, normal);
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+8;
            }
          }
          @media screen and (min-width: 1024px){
            width: 300px;
            padding: $default-font-size*2 $default-font-size*3;
          }
        }
      }
    }
    .business-key-points-wrapper{
      position: absolute;
      top: 180px;
      right: 0px;
      z-index: 1;
      @media screen and (min-width: 1024px){
        top: 340px;
      }
      @media screen and (min-width: 1200px){
        top: 360px;
      }
      .business-key-image{
        width: 140px;
        height: auto;
        margin: $default-margin;
        @media screen and (min-width: 1024px){
          width: 180px;
        }
        @media screen and (min-width: 1200px){
          width: 200px;
        }
      }
    }
  }
  .section-one{
    background-color: $alabaster;
    padding: $default-padding*2 0px;
    position: relative;
    .section-one-content{
      @media screen and (min-width: 1024px){
        padding: $default-padding*3 0px;
      }
      @media screen and (min-width: 1200px){
        padding: $default-padding*4 0px;
      }
      .content-wrapper{
        text-align: left;
        .section-heading{
          @include section-heading;
        }
        .key-point-collection{
          padding-top: $default-padding+3;
          .key-point{
            max-width: 800px;
            padding: $default-padding - 2;
            @include set-font(QuickSandRegular, $default-font-size+6, $mine-shaft, $default-line-height*3, left, normal);
            
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+8;
            }
          }
        }
      }
    }
  }
  .section-two{
    background-color: $white;
    padding: $default-padding*2 0px;
    position: relative;
    .section-two-content{
      .content-wrapper{
        text-align: left;
        .section-heading{
          @include section-heading;
        }
        .section-content{
          padding-top: $default-padding*3;
          .section-text{
            @include set-font(QuickSandRegular, $default-font-size+6, $mine-shaft, ($default-line-height*3)-4, left, normal);
            
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+8;
            }
          }
          .section-banner-header{
            @include set-font(QuickSandRegular, $default-font-size+6, $mine-shaft, ($default-line-height*3)-4, left, normal);
            padding-top: 15px;
            
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+8;
            }
          }
          .section-banner-wrapper{
            padding-top: $default-padding*3;
            .section-banner{
              width: 100%;
              height: auto;
            }
            .steps-text-wrapper{
              position: relative;
              .step{
                width: 150px;
                height: 70px;
                padding-right: 20px;
                position: absolute;
                cursor: pointer;
                @include set-font(QuickSandBold, $default-font-size+2, $white, normal, center, normal);
                @include align-item(flex, center, center, row);
                @media screen and (min-width: 1024px){
                  width: 190px;
                  height: 90px;
                  font-size: $default-font-size+4;
                }
                @media screen and (min-width: 1200px){
                  width: 230px;
                  height: 100px;
                  font-size: $default-font-size+6;
                }
              }
              .one{ left: 0em; bottom: 3.5em; z-index: 10 }
              .two{ left: 9.5em; bottom: 6em; z-index: 20 }
              .three{ left: 19em; bottom: 8.5em; z-index: 30 }
              .four{ left: 28.5em; bottom: 11em; z-index: 40 }
              .five{ left: 38.2em; bottom: 13.4em; z-index: 50 }
              .six{ left: 48em; bottom: 16em; z-index: 60; padding-right: 0px; }
  
              @media screen and (min-width: 1024px){
                .one{ left: 0em; bottom: 3.8em }
                .two{ left: 10.8em; bottom: 6.5em }
                .three{ left: 21em; bottom: 9.3em }
                .four{ left: 31.5em; bottom: 12em }
                .five{ left: 42.5em; bottom: 14.8em }
                .six{ left: 53em; bottom: 17.5em }
              }
              
              @media screen and (min-width: 1200px){
                .one{ left: 0em; bottom: 4em }
                .two{ left: 11em; bottom: 7em }
                .three{ left: 22.5em; bottom: 10em; padding-right: 30px }
                .four{ left: 33.5em; bottom: 13em; padding-right: $default-padding*3+5 }
                .five{ left: 44.5em; bottom: 15.8em }
                .six{ left: 56em; bottom: 18.5em }
              }
            }
          }
        }
      }
    }
  }
  .section-three{
    background-color: $concrete;
    padding: $default-padding*3 0px;
    position: relative;
    
    .materiality-process-step{
      padding: $default-padding*3 0px;
      @include align-item(flex, flex-start, flex-start, row);
      .step-icon{
        width: 180px;
        font-size: $default-font-size*6;
        color: $app-theme-color;
        i.icon{
          font-size: $default-padding*8;
          margin-top: $default-margin*3;
        }
        @media screen and (min-width: 1024px){
          width: 200px;
          font-size: $default-font-size*8;
        }
      }
      .step-content{
        width: 100%;
        text-align: left;
        .heading{
          padding-bottom: $default-padding*3;
          @include set-font(QuickSandBold, $default-font-size*2, $rum, normal, left, normal);
          @media screen and (min-width: 1024px){
            font-size: ($default-font-size*2)+6;
          }
        }
        .content{
          @include set-font(QuickSandMedium, $default-font-size+4, $mine-shaft, 2.25, left, normal);
          @media screen and (min-width: 1024px){
            font-size: $default-padding+6;
          }
        }
      }
    }
  }
}