@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.profile-view-wrapper {
  background-color: #fbfbfb;
  .profile-view {
    padding-top: 135px;
    padding-bottom: 135px;
    .profile-form {
      width: 500px;
      padding: 70px;
      height: 500px;
      margin: 0px 25%;
      @media screen and (max-width: 1024px){
        margin: 0px 16%;
      }
    }
    .user-name-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .icon-icn-edit {
      font-size: 18px;
      cursor: pointer;
    }
    .view-reports {
      color: #3d3d3d;
      font-size: 14px;
      text-decoration: underline;
      padding: 12px 0px;
    }
    .save-username {
      text-decoration: underline;
      cursor: pointer;
    }
    .ui.input {
      input {
        border: none;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-radius: 0px;
        padding-left: 0px;
      }
    }
    .user-name {
      font-size: $default-font-size + 14;
      line-height: normal;
      letter-spacing: -1px;
      color: #4b2369;
    }
    .user-email {
      font-size: 12px;
      letter-spacing: -0.5px;
      color: #3d3d3d;
      padding: $default-padding 0px $default-padding + 5;
    }
    .company-details-container {
      display: flex;
      justify-content: space-between;
      padding-top: $default-padding * 5;
      .company-header, .phone-header {
        font-size: $default-font-size + 4;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.9px;
        color: #aeaeae;
      }
      .company-name, .phone-number {
        font-size: $default-font-size + 16;
        line-height: normal;
        padding-top: $default-padding + 5;
      }
    }
    .change-password {
      text-align: center;
      padding: $default-padding * 14 0px 0px;
      cursor: pointer;
    }
  }
}