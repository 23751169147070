@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.analysis-types-view{

  .hero-banner-wrapper{
    position: relative;
    .hero-banner{
      width: 100%;
      height: 240px;
      position: relative;
      background-color: $flamingo;
      
      .banner-content-wrapper{
        padding: $default-padding*2 0px;
  
        @media screen and (min-width: 1024px){
          padding: $default-padding*3 0px;
        }
  
        .banner-heading{
          padding-bottom: $default-padding*2;
          @media screen and (min-width: 1024px){
            padding-bottom: $default-padding*2;
          }
          .small-heading{
            @include set-font(QuickSandLight, $default-font-size+4, $white, normal, left, normal);
            margin-bottom: $default-margin+5;
          }
          .big-heading{
            text-transform: uppercase;
            @include set-font(QuickSandMedium, $default-font-size*2, $white, normal, left, normal);
            @media screen and (min-width: 1024px){
              font-size: $default-font-size*3;
            }
          }
        }
        .banner-content-para{
          max-width: 640px;
          @include set-font(QuickSandLight, $default-font-size+4, $white, $default-line-height*2, left, normal);
          @media screen and (min-width: 1024px){
            line-height: $default-line-height*3;
          }
        }
        
      }
      
    }
  }
  
  .analysis-types-list-wrapper{
    background-color: $alabaster;
    .container{
      .analysis-types-list{
        text-align: center;
        margin: 0 auto;
        max-width: 1024px;
        padding: $default-margin*5 0px $default-margin*30 0px;
        
        .analysis-type{
          padding: $default-padding*3;
          margin: $default-margin*2 auto;
          box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
          background-color: $white;
          cursor: pointer;
          @include align-item(flex, flex-start, center, row);
          .icon-wrapper{
            width: 100px;
            img{
              width: 44px;
              height: auto;
            }
          }
          .analysis-info{
            width: calc(100% - 100px);
            .analysis-heading{
              letter-spacing: -0.4px;
              margin-bottom: $default-margin+5;
              @include set-font(QuickSandMedium, $default-font-size+8, $mine-shaft, normal, left, normal);
              @media screen and (min-width: 1024px){
                font-size: ($default-font-size*2)+2;
              }
            }
            .analysis-details{
              line-height: 1.83;
              @include set-font(QuickSandRegular, $default-font-size+6, $dove-gray, normal, left, normal);
              @media screen and (min-width: 1024px){
                font-size: $default-font-size+8;
              }
            }
          }
        }
      }
    }
  }
  .questionnaire-list {
    padding: 50px 0px 30px 0px !important;
    .questionnaire-list-heading {
      font-size: 20px;
      display: flex;
    }
  }
}