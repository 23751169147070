.main-container {
  max-width: 1000px;
  padding: 15px;
  margin: 0 auto;
}

.top-categories-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.top-categories-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}

.category-row {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 10px;
}

.category-row-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.arrow-button {
  background-color: transparent;
  border: none;
}

.arrow-right {
  position: relative;
  top: -5px;
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid gray;
  border-top: 0.2em solid gray;
  transform: rotate(40deg);
  margin-right: 0.5em;
  margin-left: 1em;
  margin-top: 8px;
}

.arrow-down {
  position: relative;
  top: -5px;
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid gray;
  border-top: 0.2em solid gray;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1em;
  margin-top: 8px;
}

.topics-list {
  list-style-type: none;
}

.topics-list > li {
  border-bottom: 1px solid lightgray;
  margin: 15px 0;
}

.topics-list > li:last-child {
  border: none;
}

.topics-list-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  padding: 10px 0;
  margin-bottom: 20px;
}

.topics-list-row > h4 {
  margin: 0;
  padding: 0;
}

.topics-list-row > h4 > span > p {
  font-weight: 400;
}

.subtopic-actions-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.subtopic-actions-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.bottom-actions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  align-items: end;
  padding: 10px;
}

.category-row.collapsed .topics-table {
  display: none;
}

.hidden {
  display: none;
}

.dropdown-title {
  padding: 0;
  margin: 0;
}

.not-checked-assessment-dropdown {
  border: 1px solid black !important;
  border-radius: 2px;
}

.not-assessed {
  display: none;
  padding: 0;
  margin: 0;
  color: red;
}

.not-assessed.hidden {
  display: none !important;
}

.not-checked-rationale-dropdown {
  border: 1px solid black !important;
  border-radius: 2px;
}

.no-rationale-set {
  display: none;
  padding: 0;
  margin: 0;
  color: red;
}

.no-rationale-set.hidden {
  display: none !important;
}

.dropdown-select {
  width: 200px;
}

#next-button-error-message {
  color: red;
}

