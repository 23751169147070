@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.review-stakeholder-configs-view {
  padding: $default-padding * 3 $default-padding * 3 $default-padding * 2;
  .header {
    height: fit-content;
    border: 1px solid !important;
    border-radius: 4px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    .icon-alert-icon {
      font-size: 36px;
      padding: 0px 20px 0px 0px;
    }
    p {
      margin: 0px;
    }
    .heading-content p:nth-child(2)  {
      color: $default-text-color;
      font-size: 16px
    }
  }
  .content {
    table {
      background-color: lightyellow !important;
    }
    .stakeholder-configurations {
      padding: 0px 0px 30px;
      .heading {
        font-size: 16px;
      }
    }
    .heading-wrapper-modal {
      display: flex;
      justify-content: space-between;
    }
  }
  .actions {
    border: none !important;
    background-color: #ffffff !important;
    display: flex;
    justify-content: space-between;
  }
}