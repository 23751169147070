.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.app-wrapper {
  min-width: 768px;
  overflow-x: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-views-wrapper {
  margin-top: 90px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.header {
  height: 60px;
  width: 100%;
  background-color: wheat;
  display: flex;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/app-icons/icomoon.eot?4y4b7h");
  src: url("../assets/fonts/app-icons/icomoon.eot?4y4b7h#iefix") format("embedded-opentype"), url("../assets/fonts/app-icons/icomoon.ttf?4y4b7h") format("truetype"), url("../assets/fonts/app-icons/icomoon.woff?4y4b7h") format("woff"), url("../assets/fonts/app-icons/icomoon.svg?4y4b7h#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-icon:before {
  content: "\e900";
  color: #f5a623;
}

.icon-arrow-icon:before {
  content: "\e901";
  color: #4b2369;
}

.icon-done-icon .path1:before {
  content: "\e902";
  color: rgb(126, 211, 33);
}

.icon-done-icon .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(251, 251, 251);
}

.icon-done-icon .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-file-text:before {
  content: "\e905";
  color: #4b2369;
}

.icon-icn-analyze-8:before {
  content: "\e906";
  color: #7b618e;
}

.icon-icn-edit:before {
  content: "\e907";
  color: #aeaeae;
}

.icon-icn-importance:before {
  content: "\e908";
  color: #7b618e;
}

.icon-icn-report:before {
  content: "\e909";
  color: #7b618e;
}

.icon-icn-stakeholders:before {
  content: "\e90a";
  color: #7b618e;
}

.icon-icn-topics:before {
  content: "\e90b";
  color: #7b618e;
}

.icon-icn-value:before {
  content: "\e90c";
  color: #7b618e;
}

.icon-trash:before {
  content: "\e90d";
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.header-view {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  padding: 10px 5px;
  box-shadow: 2px 3.5px 9.5px 0.5px rgba(107, 106, 128, 0.1);
  z-index: 1000;
}
@media print {
  .header-view {
    position: relative;
  }
}
.header-view .header-wrapper {
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.header-view .header-wrapper .left-section-wrapper {
  width: 50%;
}
.header-view .header-wrapper .left-section-wrapper .left-section {
  text-align: left;
}
.header-view .header-wrapper .left-section-wrapper .left-section .brand-logo-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}
.header-view .header-wrapper .left-section-wrapper .left-section .brand-logo-wrapper img {
  width: 100px;
  height: auto;
  cursor: pointer;
}
.header-view .header-wrapper .right-section-wrapper {
  width: 50%;
}
.header-view .header-wrapper .right-section-wrapper .right-section .logged-out-state-wrapper, .header-view .header-wrapper .right-section-wrapper .right-section .logged-in-state-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.header-view .header-wrapper .right-section-wrapper .right-section .logged-out-state-wrapper .link, .header-view .header-wrapper .right-section-wrapper .right-section .logged-in-state-wrapper .link {
  min-width: 100px;
  cursor: pointer;
  color: #747474;
  font-size: 14px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: right;
}
@media screen and (min-width: 1024px) {
  .header-view .header-wrapper .right-section-wrapper .right-section .logged-out-state-wrapper .link, .header-view .header-wrapper .right-section-wrapper .right-section .logged-in-state-wrapper .link {
    font-size: 16px;
  }
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.footer-view {
  width: 100%;
  background-color: #3b1f4f;
  padding: 50px 0px 0px 0px;
}
.footer-view .footer-content-wrapper {
  width: 720px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper {
    width: 1000px;
  }
}
.footer-view .footer-content-wrapper .left-section {
  width: 50%;
}
.footer-view .footer-content-wrapper .left-section .left-section-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.footer-view .footer-content-wrapper .left-section .left-section-content .our-location-section {
  width: 50%;
  max-width: 200px;
}
.footer-view .footer-content-wrapper .left-section .left-section-content .our-location-section .heading {
  padding-bottom: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper .left-section .left-section-content .our-location-section .heading {
    font-size: 20px;
  }
}
.footer-view .footer-content-wrapper .left-section .left-section-content .our-location-section .content {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandRegular;
  line-height: 25px;
  font-weight: normal;
  text-align: left;
}
.footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section {
  width: 50%;
}
.footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section .heading {
  padding-bottom: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section .heading {
    font-size: 20px;
  }
}
.footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section .content {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandRegular;
  line-height: 25px;
  font-weight: normal;
  text-align: left;
}
.footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section .contact-number {
  margin-top: 30px;
  color: #FFFFFF;
  font-size: 20px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper .left-section .left-section-content .get-in-touch-section .contact-number {
    font-size: 30px;
  }
}
.footer-view .footer-content-wrapper .right-section {
  width: 50%;
}
.footer-view .footer-content-wrapper .right-section .right-section-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section {
  width: 70%;
  max-width: 220px;
  text-align: left;
  margin-left: 30px;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section {
    max-width: 300px;
  }
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .heading {
  padding-bottom: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .heading {
    font-size: 20px;
  }
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .content {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandRegular;
  line-height: 25px;
  font-weight: normal;
  text-align: left;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .social-link-wrapper {
  margin-top: 30px;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .social-link-wrapper .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .about-us-section .social-link-wrapper .icon:before {
  font-size: 20px;
  display: block;
  margin-top: 10px;
  color: grey;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .brand-logo-wrapper {
  width: 30%;
  margin-top: 40px;
}
.footer-view .footer-content-wrapper .right-section .right-section-content .brand-logo-wrapper img {
  width: 80px;
  height: auto;
}
.footer-view .all-right-reserved-section {
  margin-top: 30px;
  padding: 10px 0px;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
.footer-view .all-right-reserved-section .copy-right-text-wrapper {
  width: 720px;
  color: #FFFFFF;
  font-size: 10px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .footer-view .all-right-reserved-section .copy-right-text-wrapper {
    width: 1000px;
  }
}
.footer-view .all-right-reserved-section .copy-right-text-wrapper .icon:before {
  color: #FFFFFF;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.auth-module-view {
  width: 520px !important;
}
.auth-module-view .content {
  padding: 30px !important;
  /* start :: login view styles */
  /* end :: login view styles */
  /* start :: sign up view styles */
  /* end :: sign up view styles */
  /* start :: forgot password view styles */
  /* end :: forgot password view styles */
  /* start :: password reset link sent view styles */
  /* end :: password reset link sent view styles */
  /* start :: login view styles */
  /* end :: login view styles */
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content {
    padding: 50px !important;
    padding-bottom: 30px !important;
  }
}
.auth-module-view .content .login-view .heading {
  padding-bottom: 30px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .login-view .heading {
    font-size: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .login-view .heading {
    padding-bottom: 50px;
  }
}
.auth-module-view .content .login-view .ui.form .btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .login-view .ui.form .btn-wrapper {
    margin-top: 50px;
  }
}
.auth-module-view .content .login-view .ui.form .btn-wrapper .app-btn {
  width: auto;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
.auth-module-view .content .login-view .ui.form .btn-wrapper .forgot-password-link {
  cursor: pointer;
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
.auth-module-view .content .login-view .ui.form .sign-up-here-link {
  padding-top: 30px;
  cursor: pointer;
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .login-view .ui.form .sign-up-here-link {
    padding-top: 50px;
  }
}
.auth-module-view .content .register-view .heading {
  padding-bottom: 30px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .register-view .heading {
    font-size: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .register-view .heading {
    padding-bottom: 50px;
  }
}
.auth-module-view .content .register-view .ui.form .checkbox-wrapper {
  margin-top: 20px;
}
.auth-module-view .content .register-view .ui.form .checkbox-wrapper .ui.checkbox {
  color: #686b78;
  font-size: 12px !important;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.auth-module-view .content .register-view .ui.form .btn-wrapper {
  margin-top: 30px;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .register-view .ui.form .btn-wrapper {
    margin-top: 40px;
  }
}
.auth-module-view .content .register-view .ui.form .btn-wrapper .app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
.auth-module-view .content .register-view .ui.form .login-here-link {
  padding-top: 30px;
  cursor: pointer;
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .register-view .ui.form .login-here-link {
    padding-top: 50px;
  }
}
.auth-module-view .content .forgot-password-view .heading {
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .forgot-password-view .heading {
    font-size: 30px;
  }
}
.auth-module-view .content .forgot-password-view .form-text {
  padding-top: 12px;
  padding-bottom: 30px;
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .forgot-password-view .form-text {
    padding-bottom: 50px;
  }
}
.auth-module-view .content .forgot-password-view .ui.form .btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .forgot-password-view .ui.form .btn-wrapper {
    margin-top: 50px;
  }
}
.auth-module-view .content .forgot-password-view .ui.form .btn-wrapper .app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
.auth-module-view .content .forgot-password-view .ui.form .btn-wrapper .login-link {
  cursor: pointer;
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
}
.auth-module-view .content .password-reset-link-sent-view .heading, .auth-module-view .content .register-success-view .heading {
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  padding-bottom: 30px;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .password-reset-link-sent-view .heading, .auth-module-view .content .register-success-view .heading {
    font-size: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .password-reset-link-sent-view .heading, .auth-module-view .content .register-success-view .heading {
    padding-bottom: 40px;
  }
}
.auth-module-view .content .password-reset-link-sent-view .form-text, .auth-module-view .content .register-success-view .form-text {
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .password-reset-link-sent-view .form-text, .auth-module-view .content .register-success-view .form-text {
    padding-bottom: 40px;
  }
}
.auth-module-view .content .password-reset-link-sent-view .btn-wrapper, .auth-module-view .content .register-success-view .btn-wrapper {
  margin-top: 30px;
}
@media screen and (min-width: 1024px) {
  .auth-module-view .content .password-reset-link-sent-view .btn-wrapper, .auth-module-view .content .register-success-view .btn-wrapper {
    margin-top: 40px;
  }
}
.auth-module-view .content .password-reset-link-sent-view .btn-wrapper .app-btn, .auth-module-view .content .register-success-view .btn-wrapper .app-btn {
  width: 140px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.unordered-list {
  padding-left: 20px;
}
.unordered-list .list-item {
  list-style-position: outside;
}

.relative-position {
  position: relative;
}

.clear-opacity {
  opacity: 1 !important;
}

.error-message-view {
  padding-top: 5px;
  margin-bottom: 5px;
  color: #ff5066;
  font-size: 12px;
  font-family: QuickSandLight;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .error-message-view {
    font-size: 14px;
  }
}

.success-message-view {
  padding-top: 5px;
  margin-bottom: 5px;
  color: #2adb13;
  font-size: 12px;
  font-family: QuickSandLight;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .success-message-view {
    font-size: 14px;
  }
}

.opacity-btn {
  opacity: 0.5;
}

.empty-state-view {
  height: 200px;
  color: #686b78;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.up-arrow, .down-arrow {
  padding-left: 10px;
}

.opacity-preview {
  pointer-events: none;
  opacity: 0.3;
}

.reduced-opacity-disabled {
  opacity: 0.3;
}

.empty-div {
  margin: 50%;
}

.cursor-not-allowed {
  pointer-events: none;
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bolder;
}

.selected-perception {
  color: #3d3d3d;
}

.print-link {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #4e266a;
  margin-bottom: 40px;
  margin-left: 50px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

/* start :: form styles */
.ui.form .field {
  width: 100%;
  margin-bottom: 10px;
}
.ui.form .field input {
  height: 50px;
  letter-spacing: 1px;
  color: #686b78;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.ui.form .error-msg-wrapper {
  text-align: center;
  position: relative;
}

.ui.checkbox label:before,
.ui.checkbox .box:before {
  border: 2px solid #2f3d51;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  top: 1px;
}

.ui input.highlight-input {
  border-color: #ff5066 !important;
}

/* end :: form styles */
.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.ui.button.app-btn {
  width: 160px;
  height: 34px;
  border-radius: 4px;
}
@media screen and (min-width: 1024px) {
  .ui.button.app-btn {
    width: 200px;
    height: 40px;
  }
}

.ui.button.purple-solid-btn {
  background-color: #7b618e;
  color: #FFFFFF;
  font-size: 12px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: center;
}

.ui.button.orange-solid-btn {
  background-color: #f26221;
  color: #FFFFFF;
  font-size: 12px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: center;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.email-verify-container {
  padding: 60px 0px;
  display: flex !important;
  justify-content: center;
}
.email-verify-container .email-verify-wrapper {
  width: 70%;
}
.email-verify-container .email-verify-wrapper button {
  font-size: 20px;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 50px;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper img {
  width: 60px;
  margin-right: 20px;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper .icon-alert-icon {
  font-size: 55px;
  padding: 30px;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper .message-content {
  font-size: 30px;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper .message-content div {
  line-height: 1em;
}
.email-verify-container .email-verify-wrapper .message-content-wrapper .message-content div:nth-child(1) {
  color: red;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.landing-page-view .hero-banner-wrapper {
  position: relative;
}
.landing-page-view .hero-banner-wrapper .hero-banner {
  width: 100%;
  height: 300px;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .hero-banner {
    height: 500px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper {
  max-width: 400px;
  position: absolute;
  text-align: left;
  padding-top: 20px;
  color: white;
  z-index: 10;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper {
    padding-top: 77px;
    max-width: 600px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading {
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading {
    padding-bottom: 20px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading .small-heading {
  color: #FFFFFF;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  margin-bottom: 5px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading .small-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading .big-heading {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 20px;
  font-family: QuickSandBold;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-heading .big-heading {
    font-size: 40px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-content-para {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: 20px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .banner-content-para {
    color: #FFFFFF;
    font-size: 18px;
    font-family: QuickSandLight;
    line-height: 30px;
    font-weight: normal;
    text-align: left;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper {
  margin-top: 20px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper {
    margin-top: 70px;
  }
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper .contact-us-btn {
  position: absolute;
  width: 160px;
  border-radius: 100px;
  background-color: #FFFFFF;
  box-shadow: 0 28px 43px 0 rgba(0, 0, 0, 0.11);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper .contact-us-btn .btn-text {
  color: #43484d;
  font-size: 14px;
  font-family: QuickSandBold;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper .contact-us-btn .btn-text {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .banner-content-wrapper .contact-us-btn-wrapper .contact-us-btn {
    width: 300px;
    padding: 20px 30px;
  }
}
.landing-page-view .hero-banner-wrapper .business-key-points-wrapper {
  position: absolute;
  top: 180px;
  right: 0px;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .business-key-points-wrapper {
    top: 340px;
  }
}
@media screen and (min-width: 1200px) {
  .landing-page-view .hero-banner-wrapper .business-key-points-wrapper {
    top: 360px;
  }
}
.landing-page-view .hero-banner-wrapper .business-key-points-wrapper .business-key-image {
  width: 140px;
  height: auto;
  margin: 10px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .hero-banner-wrapper .business-key-points-wrapper .business-key-image {
    width: 180px;
  }
}
@media screen and (min-width: 1200px) {
  .landing-page-view .hero-banner-wrapper .business-key-points-wrapper .business-key-image {
    width: 200px;
  }
}
.landing-page-view .section-one {
  background-color: #F8F8F8;
  padding: 20px 0px;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-one .section-one-content {
    padding: 30px 0px;
  }
}
@media screen and (min-width: 1200px) {
  .landing-page-view .section-one .section-one-content {
    padding: 40px 0px;
  }
}
.landing-page-view .section-one .section-one-content .content-wrapper {
  text-align: left;
}
.landing-page-view .section-one .section-one-content .content-wrapper .section-heading {
  max-width: 350px;
  padding-top: 20px;
  color: #1e0032;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-one .section-one-content .content-wrapper .section-heading {
    max-width: 560px;
    font-size: 26px;
  }
}
.landing-page-view .section-one .section-one-content .content-wrapper .key-point-collection {
  padding-top: 13px;
}
.landing-page-view .section-one .section-one-content .content-wrapper .key-point-collection .key-point {
  max-width: 800px;
  padding: 8px;
  color: #3d3d3d;
  font-size: 16px;
  font-family: QuickSandRegular;
  line-height: 30px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-one .section-one-content .content-wrapper .key-point-collection .key-point {
    font-size: 18px;
  }
}
.landing-page-view .section-two {
  background-color: #FFFFFF;
  padding: 20px 0px;
  position: relative;
}
.landing-page-view .section-two .section-two-content .content-wrapper {
  text-align: left;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-heading {
  max-width: 350px;
  padding-top: 20px;
  color: #1e0032;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-heading {
    max-width: 560px;
    font-size: 26px;
  }
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content {
  padding-top: 30px;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-text {
  color: #3d3d3d;
  font-size: 16px;
  font-family: QuickSandRegular;
  line-height: 26px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-text {
    font-size: 18px;
  }
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-header {
  color: #3d3d3d;
  font-size: 16px;
  font-family: QuickSandRegular;
  line-height: 26px;
  font-weight: normal;
  text-align: left;
  padding-top: 15px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-header {
    font-size: 18px;
  }
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper {
  padding-top: 30px;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .section-banner {
  width: 100%;
  height: auto;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper {
  position: relative;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .step {
  width: 150px;
  height: 70px;
  padding-right: 20px;
  position: absolute;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 12px;
  font-family: QuickSandBold;
  line-height: normal;
  font-weight: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .step {
    width: 190px;
    height: 90px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1200px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .step {
    width: 230px;
    height: 100px;
    font-size: 16px;
  }
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .one {
  left: 0em;
  bottom: 3.5em;
  z-index: 10;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .two {
  left: 9.5em;
  bottom: 6em;
  z-index: 20;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .three {
  left: 19em;
  bottom: 8.5em;
  z-index: 30;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .four {
  left: 28.5em;
  bottom: 11em;
  z-index: 40;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .five {
  left: 38.2em;
  bottom: 13.4em;
  z-index: 50;
}
.landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .six {
  left: 48em;
  bottom: 16em;
  z-index: 60;
  padding-right: 0px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .one {
    left: 0em;
    bottom: 3.8em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .two {
    left: 10.8em;
    bottom: 6.5em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .three {
    left: 21em;
    bottom: 9.3em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .four {
    left: 31.5em;
    bottom: 12em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .five {
    left: 42.5em;
    bottom: 14.8em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .six {
    left: 53em;
    bottom: 17.5em;
  }
}
@media screen and (min-width: 1200px) {
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .one {
    left: 0em;
    bottom: 4em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .two {
    left: 11em;
    bottom: 7em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .three {
    left: 22.5em;
    bottom: 10em;
    padding-right: 30px;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .four {
    left: 33.5em;
    bottom: 13em;
    padding-right: 35px;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .five {
    left: 44.5em;
    bottom: 15.8em;
  }
  .landing-page-view .section-two .section-two-content .content-wrapper .section-content .section-banner-wrapper .steps-text-wrapper .six {
    left: 56em;
    bottom: 18.5em;
  }
}
.landing-page-view .section-three {
  background-color: #f2f2f2;
  padding: 30px 0px;
  position: relative;
}
.landing-page-view .section-three .materiality-process-step {
  padding: 30px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.landing-page-view .section-three .materiality-process-step .step-icon {
  width: 180px;
  font-size: 60px;
  color: #3B214E;
}
.landing-page-view .section-three .materiality-process-step .step-icon i.icon {
  font-size: 80px;
  margin-top: 30px;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-three .materiality-process-step .step-icon {
    width: 200px;
    font-size: 80px;
  }
}
.landing-page-view .section-three .materiality-process-step .step-content {
  width: 100%;
  text-align: left;
}
.landing-page-view .section-three .materiality-process-step .step-content .heading {
  padding-bottom: 30px;
  color: #7b618e;
  font-size: 20px;
  font-family: QuickSandBold;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-three .materiality-process-step .step-content .heading {
    font-size: 26px;
  }
}
.landing-page-view .section-three .materiality-process-step .step-content .content {
  color: #3d3d3d;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: 2.25;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .landing-page-view .section-three .materiality-process-step .step-content .content {
    font-size: 16px;
  }
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.analysis-types-view .hero-banner-wrapper {
  position: relative;
}
.analysis-types-view .hero-banner-wrapper .hero-banner {
  width: 100%;
  height: 240px;
  position: relative;
  background-color: #f26221;
}
.analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
  padding: 20px 0px;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
    padding: 30px 0px;
  }
}
.analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
    padding-bottom: 20px;
  }
}
.analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .small-heading {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  margin-bottom: 15px;
}
.analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
    font-size: 30px;
  }
}
.analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
  max-width: 640px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: 20px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
    line-height: 30px;
  }
}
.analysis-types-view .analysis-types-list-wrapper {
  background-color: #F8F8F8;
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list {
  text-align: center;
  margin: 0 auto;
  max-width: 1024px;
  padding: 50px 0px 300px 0px;
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type {
  padding: 30px;
  margin: 20px auto;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .icon-wrapper {
  width: 100px;
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .icon-wrapper img {
  width: 44px;
  height: auto;
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .analysis-info {
  width: calc(100% - 100px);
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .analysis-info .analysis-heading {
  letter-spacing: -0.4px;
  margin-bottom: 15px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .analysis-info .analysis-heading {
    font-size: 22px;
  }
}
.analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .analysis-info .analysis-details {
  line-height: 1.83;
  color: #686868;
  font-size: 16px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .analysis-types-view .analysis-types-list-wrapper .container .analysis-types-list .analysis-type .analysis-info .analysis-details {
    font-size: 18px;
  }
}
.analysis-types-view .questionnaire-list {
  padding: 50px 0px 30px 0px !important;
}
.analysis-types-view .questionnaire-list .questionnaire-list-heading {
  font-size: 20px;
  display: flex;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.stakeholder-insights-view .loading-wrapper {
  position: fixed;
}
.stakeholder-insights-view .hero-banner-wrapper {
  position: relative;
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner {
  width: 100%;
  height: 200px;
  position: relative;
  background-color: #f26221;
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
  padding: 20px 0px;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
    padding: 30px 0px;
  }
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
    padding-bottom: 20px;
  }
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .small-heading {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  margin-bottom: 15px;
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
    font-size: 30px;
  }
}
.stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
  max-width: 640px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: 20px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
    line-height: 30px;
  }
}
.stakeholder-insights-view .breadcrumb-wrapper {
  background-color: #FFFFFF;
  padding: 20px;
  border: solid 1px #e7e7e7;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container {
  display: flex;
  justify-content: space-between;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb {
  text-transform: uppercase;
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb a {
  color: #aeaeae;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb .divider {
  margin: 0px 30px;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb .active.section {
  color: #4e266a;
}
.stakeholder-insights-view .breadcrumb-wrapper .ui.container .print-link {
  margin: 0px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel {
  width: 30%;
  padding-top: 30px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel {
    padding-top: 50px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .time-line-bar {
  height: 65%;
  border-left: 1px solid #e7e7e7;
  position: absolute;
  top: 70px;
  left: 15px;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .time-line-bar {
    left: 20px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step {
  padding-bottom: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step {
    padding-bottom: 120px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper {
  width: 20%;
  z-index: 10;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper .icon {
  width: 30px;
  height: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 50%;
  background-color: #FFFFFF;
  padding: 4px 10px;
  color: #e7e7e7;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 6px 13px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper .active {
  border-color: #4e266a;
  color: #4e266a;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper .complete-icon {
  width: 30px;
  height: auto;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .icon-wrapper .complete-icon {
    width: 40px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper {
  width: 80%;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper .heading {
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  padding-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper .heading {
    font-size: 18px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper .sub-heading {
  color: #3d3d3d;
  font-size: 12px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper .sub-heading {
    font-size: 14px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .left-side-panel .stakeholder-insights-steps-wrapper .stakeholder-insights-step .content-wrapper .active {
  color: #4e266a;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel {
  width: 70%;
  min-height: 500px;
  padding: 30px;
  border-left: 1px solid #e7e7e7;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel {
    padding: 50px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .back-btn-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .back-btn-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .back-btn-wrapper {
  margin-bottom: 25px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn {
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn .icon-wrapper {
  margin-right: 10px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn .btn-text, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn .btn-text, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn .btn-text {
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  letter-spacing: -0.2px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .view-heading-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .view-heading-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .view-heading-wrapper {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .view-heading-wrapper .heading, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .view-heading-wrapper .heading, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .view-heading-wrapper .heading {
  color: #3d3d3d;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .view-heading-wrapper .heading, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .view-heading-wrapper .heading, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .view-heading-wrapper .heading {
    font-size: 18px;
  }
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .add-stake-holder-form-view, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .add-stake-holder-form-view, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .add-stake-holder-form-view {
  margin-bottom: 40px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
  font-size: 13px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input[placeholder], .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input[placeholder], .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .add-stake-holder-form-view .ui.form .field .stakeholder-name input[placeholder] {
  text-overflow: ellipsis;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field {
  margin-bottom: 20px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .stakeholder-name, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .stakeholder-name {
  color: #3d3d3d;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .stakeholder-note, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .stakeholder-note {
  color: rgba(61, 61, 61, 0.5);
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .icon-wrapper {
  font-size: 20px;
  color: #e7e7e7;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon {
  cursor: pointer;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .form-footer, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .form-footer {
  border-top: 1px solid #e7e7e7;
  margin-top: 80px;
}
.stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .stakeholder-insights-view .main-content-wrapper .stakeholder-insights-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .form-footer .ui.button {
  margin-top: 30px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.business-ability-view .loading-wrapper {
  position: fixed;
}
.business-ability-view .hero-banner-wrapper {
  position: relative;
}
.business-ability-view .hero-banner-wrapper .hero-banner {
  width: 100%;
  height: 200px;
  position: relative;
  background-color: #f26221;
}
.business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
  padding: 20px 0px;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper {
    padding: 30px 0px;
  }
}
.business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading {
    padding-bottom: 20px;
  }
}
.business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .small-heading {
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  margin-bottom: 15px;
}
.business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-heading .big-heading {
    font-size: 30px;
  }
}
.business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
  max-width: 640px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: QuickSandLight;
  line-height: 20px;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .hero-banner-wrapper .hero-banner .banner-content-wrapper .banner-content-para {
    line-height: 30px;
  }
}
.business-ability-view .breadcrumb-wrapper {
  background-color: #FFFFFF;
  padding: 20px;
  border: solid 1px #e7e7e7;
}
.business-ability-view .breadcrumb-wrapper .ui.container {
  display: flex;
  justify-content: space-between;
}
.business-ability-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb {
  text-transform: uppercase;
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.business-ability-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb a {
  color: #aeaeae;
}
.business-ability-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb .divider {
  margin: 0px 30px;
}
.business-ability-view .breadcrumb-wrapper .ui.container .ui.large.breadcrumb .active.section {
  color: #4e266a;
}
.business-ability-view .breadcrumb-wrapper .ui.container .print-link {
  margin: 0px;
}
.business-ability-view .main-content-wrapper .business-ability-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel {
  width: 30%;
  padding-top: 30px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel {
    padding-top: 50px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .time-line-bar {
  height: 65%;
  border-left: 1px solid #e7e7e7;
  position: absolute;
  top: 80px;
  left: 15px;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .time-line-bar {
    top: 90px;
    left: 20px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step {
  padding-bottom: 100px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step {
    padding-bottom: 120px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper {
  width: 20%;
  z-index: 10;
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper .icon {
  width: 30px;
  height: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 50%;
  background-color: #FFFFFF;
  padding: 4px 10px;
  color: #e7e7e7;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 6px 13px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper .active {
  border-color: #4e266a;
  color: #4e266a;
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper .complete-icon {
  width: 30px;
  height: auto;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .icon-wrapper .complete-icon {
    width: 40px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper {
  width: 80%;
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper .heading {
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  padding-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper .heading {
    font-size: 18px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper .sub-heading {
  color: #3d3d3d;
  font-size: 12px;
  font-family: QuickSandRegular;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper .sub-heading {
    font-size: 14px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .left-side-panel .business-ability-steps-wrapper .business-ability-step .content-wrapper .active {
  color: #4e266a;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel {
  width: 70%;
  min-height: 500px;
  padding: 30px;
  padding-right: 0px;
  border-left: 1px solid #e7e7e7;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel {
    padding: 50px 0px 50px 30px;
  }
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view {
    width: 101%;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .back-btn-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .back-btn-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .back-btn-wrapper {
  margin-bottom: 25px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn {
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn .icon-wrapper {
  margin-right: 10px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .back-btn-wrapper .back-btn .btn-text, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .back-btn-wrapper .back-btn .btn-text, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .back-btn-wrapper .back-btn .btn-text {
  color: #aeaeae;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
  letter-spacing: -0.2px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .view-heading-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .view-heading-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .view-heading-wrapper {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .view-heading-wrapper .heading, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .view-heading-wrapper .heading, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .view-heading-wrapper .heading {
  color: #3d3d3d;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .view-heading-wrapper .heading, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .view-heading-wrapper .heading, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .view-heading-wrapper .heading {
    font-size: 18px;
  }
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .add-business-criteria-form-view, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .add-business-criteria-form-view, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .add-business-criteria-form-view {
  margin-bottom: 40px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px;
  font-size: 13px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input[placeholder], .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input[placeholder], .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .add-business-criteria-form-view .ui.form .field .business-criteria-name input[placeholder] {
  text-overflow: ellipsis;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field {
  margin-bottom: 20px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .business-criteria-name, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .business-criteria-name {
  color: #3d3d3d;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .business-criteria-note, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .business-criteria-note {
  color: rgba(61, 61, 61, 0.5);
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .icon-wrapper, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .icon-wrapper {
  font-size: 20px;
  color: #e7e7e7;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .icon-wrapper .icon, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .icon-wrapper .icon {
  cursor: pointer;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .strategy-period-select, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .strategy-period-select {
  border: 1px solid #e7e7e7;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  height: 50px;
  letter-spacing: 1px;
  color: #686b78;
  font-size: 16px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .field .strategy-period-select.selected, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .field .strategy-period-select.selected {
  background-color: #e7e7e7;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .other-startegy-period, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .other-startegy-period {
  padding: 10px;
  font-size: 16px;
  color: darkslateblue;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .form-footer, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .form-footer {
  border-top: 1px solid #e7e7e7;
  margin-top: 80px;
}
.business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-one-view .step-three-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-two-view .step-three-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-one-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-two-form-wrapper .ui.form .form-footer .ui.button, .business-ability-view .main-content-wrapper .business-ability-container .right-side-panel .step-three-view .step-three-form-wrapper .ui.form .form-footer .ui.button {
  margin-top: 30px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.set-password-view-container {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.set-password-view-container .set-password-view {
  width: 500px;
  padding: 50px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}
.set-password-view-container .set-password-view .heading {
  padding-bottom: 30px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .set-password-view .heading {
    font-size: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .set-password-view .heading {
    padding-bottom: 50px;
  }
}
.set-password-view-container .set-password-view .ui.form .btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .set-password-view .ui.form .btn-wrapper {
    margin-top: 50px;
  }
}
.set-password-view-container .set-password-view .ui.form .btn-wrapper .app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}
.set-password-view-container .password-set-success-view .heading {
  padding-bottom: 30px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2f3d51;
  font-size: 20px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .password-set-success-view .heading {
    font-size: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .password-set-success-view .heading {
    padding-bottom: 50px;
  }
}
.set-password-view-container .password-set-success-view .form-text {
  color: #2f3d51;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: left;
  padding-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .password-set-success-view .form-text {
    padding-bottom: 40px;
  }
}
.set-password-view-container .password-set-success-view .btn-wrapper {
  margin-top: 30px;
}
@media screen and (min-width: 1024px) {
  .set-password-view-container .password-set-success-view .btn-wrapper {
    margin-top: 40px;
  }
}
.set-password-view-container .password-set-success-view .btn-wrapper .app-btn {
  width: 140px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: 1px solid #2f3d51;
  color: #ffffff;
  font-size: 14px;
  font-family: QuickSandMedium;
  line-height: normal;
  font-weight: 500;
  text-align: center;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.select-stake-holder-wrapper {
  width: 55% !important;
}
.select-stake-holder-wrapper .small-header-container {
  display: flex;
  justify-content: space-between;
}
.select-stake-holder-wrapper .small-header-container .small-header {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #4e266a;
}
@media screen and (max-width: 1024px) {
  .select-stake-holder-wrapper .small-header-container .small-header {
    font-size: 12px;
  }
}
.select-stake-holder-wrapper .small-header-container .toggle-questionnaire {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #4e266a;
  text-decoration: underline;
}
@media screen and (max-width: 1024px) {
  .select-stake-holder-wrapper .small-header-container .toggle-questionnaire {
    font-size: 12px;
  }
}
.select-stake-holder-wrapper .large-header-container {
  padding-bottom: 30px;
}
.select-stake-holder-wrapper .choose-respondent {
  padding: 10px 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #4e266a;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}
.select-stake-holder-wrapper .large-header {
  font-size: 30px;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: #3d3d3d;
}
@media screen and (max-width: 1024px) {
  .select-stake-holder-wrapper .large-header {
    font-size: 20px;
  }
}
.select-stake-holder-wrapper .ui.input.fluid {
  margin-bottom: 40px;
}
.select-stake-holder-wrapper .form-footer {
  margin-bottom: 30px;
}
.select-stake-holder-wrapper .questionnaire-btn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
}

.ui.form {
  height: 100%;
}
.ui.form .grouped.fields .field {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
}
@media screen and (max-width: 1024px) {
  .ui.form .grouped.fields .field {
    font-size: 20px;
  }
}
.ui.form .grouped.fields .field label {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  cursor: pointer;
}
.ui.form .grouped.fields .field input {
  width: 25px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .ui.form .grouped.fields .field input {
    width: 20px;
  }
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.analyse-drivers-heading {
  margin: 0px auto;
  font-size: 36px;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: #3d3d3d;
  padding-top: 50px;
}
@media screen and (max-width: 1024px) {
  .analyse-drivers-heading {
    font-size: 30px;
  }
}

.analyse-divers-wrapper {
  text-align: center;
  padding: 50px 0px 300px 0px;
}
.analyse-divers-wrapper .analyse-driver-item {
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.analyse-divers-wrapper .analyse-driver-item span {
  font-size: 18px;
}
.analyse-divers-wrapper .analyse-driver-item .analyse-drivers-file-info {
  padding: 15px 30px;
}
.analyse-divers-wrapper .analyse-driver-item .analyse-drivers-file-info .filename-info {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  word-break: break-all;
  text-align: left;
  padding: 5px 0px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
}
.analyse-divers-wrapper .analyse-driver-item .analyse-drivers-file-info .uploadedby-info {
  font-size: 12px;
  color: #686868;
  float: left;
}
.analyse-divers-wrapper .analyse-driver-item .upload-date {
  color: #686868;
  font-size: 12px;
  text-align: right;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.list-of-topics-heading {
  font-size: 30px;
  padding-top: 50px;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #f26221;
}
@media screen and (max-width: 1024px) {
  .list-of-topics-heading {
    font-size: 20px;
  }
}

.list-of-topics-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.list-of-topics-heading-wrapper .print-link {
  margin: 0px;
}

.list-of-topics-container {
  background-color: #fbfbfb;
}

.list-of-topics-wrapper {
  text-align: center;
  padding: 50px 0px 300px 0px;
}
.list-of-topics-wrapper .topic-item {
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.list-of-topics-wrapper .topic-item .topic-list-number {
  font-size: 30px;
  font-weight: bold;
  color: #3d3d3d;
}
.list-of-topics-wrapper .topic-item .topic-info {
  padding: 15px 30px;
}
.list-of-topics-wrapper .topic-item .topic-info .topic-name {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  word-break: break-all;
  text-align: left;
  padding: 5px 0px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
}
.list-of-topics-wrapper .topic-item .topic-info .topic-description-info {
  font-size: 18px;
  line-height: 1.83;
  color: #686868;
  float: left;
  text-align: left;
}
.list-of-topics-wrapper .topic-item .topic-category {
  color: #686868;
  font-size: 12px;
  text-align: right;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.single-question-view {
  padding: 30px 0px;
}
.single-question-view .single-question-container {
  width: 60%;
}
.single-question-view .single-question-container .error-message-view {
  margin: 15px 0px 0px;
  display: flex;
  justify-content: flex-end;
  font-weight: 800;
}
.single-question-view .single-question-container .question-header-one {
  padding-left: 100px;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #aeaeae;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .question-header-one {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .question-header-one .heading-left {
  display: flex;
}
.single-question-view .single-question-container .question-header-one .heading-right .print-link {
  margin: 0px;
  margin-top: 10px;
  float: left;
}
.single-question-view .single-question-container .question-header-one .weightage {
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: right;
  color: #3d3d3d;
  cursor: pointer;
}
.single-question-view .single-question-container .question-header-one .answered-count {
  padding-left: 15px;
}
.single-question-view .single-question-container .question-header-2 {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-top: 60px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #4e266a;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .question-header-2 {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .question-content {
  align-items: center;
  padding-top: 30px;
}
.single-question-view .single-question-container .question-content .question-number {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: #b7caff;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .question-content .question-number {
    width: 30px !important;
    height: 30px;
    font-size: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.single-question-view .single-question-container .question-content .question {
  padding-left: 62px;
  font-size: 26px;
  padding-top: 40px;
  line-height: 1.69;
  letter-spacing: normal;
  color: #3d3d3d;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .question-content .question {
    font-size: 20px;
  }
}
.single-question-view .single-question-container .read-more {
  padding-left: 100px;
  padding-top: 30px;
  text-decoration: overline;
  font-size: 16px;
  letter-spacing: -0.3px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .read-more {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .scoring-the-question {
  padding-top: 100px;
  padding-left: 100px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .scoring-the-question {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .scoring-the-question .button {
  width: 240px;
  height: 50px;
}
.single-question-view .single-question-container .question-description {
  padding-top: 50px;
  padding-left: 100px;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .question-description {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .importance {
  display: flex;
  padding-left: 100px;
  margin-top: 15px;
  color: #D3D3D3;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .importance {
    padding-left: 80px;
  }
}
.single-question-view .single-question-container .importance .rise {
  padding-right: 20px;
  cursor: pointer;
}
.single-question-view .single-question-container .importance .less-relevent {
  cursor: pointer;
}
.single-question-view .single-question-container .questionnaie-footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.single-question-view .single-question-container .questionnaie-footer .next-btn {
  width: 400px;
  height: 50px;
  margin-left: 100px;
  margin-top: 50px;
  font-size: 15px;
  color: #ffffff;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .questionnaie-footer .next-btn {
    margin-left: 73px;
  }
}
.single-question-view .single-question-container .questionnaie-footer .view-results {
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: right;
  color: #3d3d3d;
}
@media screen and (max-width: 1024px) {
  .single-question-view .single-question-container .questionnaie-footer .view-results {
    font-size: 14px;
    white-space: nowrap;
  }
}

.questionnaire-question-wrapper .height-fill {
  height: -webkit-fill-available;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.different-category-model .header {
  font-size: 23px !important;
  letter-spacing: -0.4px;
  border-bottom: none !important;
  padding: 50px !important;
  padding-bottom: 40px !important;
  display: flex !important;
  justify-content: space-between;
}
.different-category-model .header .close-icon {
  cursor: pointer;
}
.different-category-model .content {
  padding-bottom: 50px !important;
}
.different-category-model .content .category-item {
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #e7e7e7;
  align-items: center;
  padding: 15px;
  margin: 10px 29px;
  cursor: pointer;
}
.different-category-model .content .category-item .topics-answered {
  display: flex;
  justify-content: space-between;
}
.different-category-model .content .category-item .topics-answered .done-icon {
  width: 20px;
}
.different-category-model .content .view-results {
  color: black;
  text-decoration: underline;
  margin: 28px 29px 10px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.edit-weightage-model .header {
  font-size: 23px !important;
  letter-spacing: -0.4px;
  border-bottom: none !important;
  padding: 50px !important;
  padding-bottom: 40px !important;
  display: flex !important;
  justify-content: space-between;
}
.edit-weightage-model .header .close-icon {
  cursor: pointer;
}
.edit-weightage-model .content {
  padding-bottom: 50px !important;
}
.edit-weightage-model .content form {
  padding: 0px 30px;
}
.edit-weightage-model .content form .save-weightage {
  margin-top: 50px !important;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.questionnaire-preview-wrapper .ui.container {
  width: 65%;
}
@media print {
  .questionnaire-preview-wrapper .ui.container {
    width: 100%;
  }
}
.questionnaire-preview-wrapper .heading-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.questionnaire-preview-wrapper .print-link {
  font-size: 18px;
}
.questionnaire-preview-wrapper .heading {
  padding-top: 50px;
  font-size: 39px;
  line-height: 1.03;
  letter-spacing: 1px;
}
@media screen and (max-width: 1024px) {
  .questionnaire-preview-wrapper .heading {
    font-size: 30px;
  }
}
.questionnaire-preview-wrapper .back-to-questionnaire {
  font-size: 15px;
  text-decoration: underline;
  color: #3d3d3d;
  padding: 20px 0px;
}
.questionnaire-preview-wrapper .questionnaire-list-preview {
  padding-top: 100px;
}
.questionnaire-preview-wrapper .respondant-container {
  font-size: 22px;
}
.questionnaire-preview-wrapper .category-name {
  font-size: 22px;
  line-height: normal;
  letter-spacing: normal;
  color: #3d3d3d;
  margin-bottom: 30px;
}
.questionnaire-preview-wrapper .questionnaire-item {
  margin-bottom: 100px;
}
.questionnaire-preview-wrapper .questionnaire-item .category-weight-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.questionnaire-preview-wrapper .questionnaire-item .category-weight-wrapper .weightage {
  cursor: pointer;
  color: #4e266a;
}
@media print {
  .questionnaire-preview-wrapper .questionnaire-item .category-weight-wrapper .weightage {
    display: none;
  }
}
.questionnaire-preview-wrapper .question-content {
  margin: 0px;
  align-items: center;
}
.questionnaire-preview-wrapper .question-content .question-number {
  border-radius: 4px;
  background-color: #b7caff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: space-around;
}
.questionnaire-preview-wrapper .question-content .topic-name {
  padding-left: 30px !important;
  font-size: 16px;
  line-height: 2.75;
}
.questionnaire-preview-wrapper .question-content .icon-icn-edit {
  cursor: pointer;
}
.questionnaire-preview-wrapper .preview-btn-grade {
  width: 240px;
  height: 50px;
  border-radius: 4px;
  margin-top: 15px;
  color: #ffffff;
}
@media print {
  .questionnaire-preview-wrapper .preview-btn-grade {
    font-size: 18px;
  }
}
.questionnaire-preview-wrapper .orange-btn {
  background-color: #f5a623;
  border: solid 1px #f5a623;
}
.questionnaire-preview-wrapper .green-btn {
  background-color: #7ed321;
  border: solid 1px #7ed321;
}
.questionnaire-preview-wrapper .red-btn {
  background-color: #ff5066;
  border: solid 1px #ff5066;
}
.questionnaire-preview-wrapper .question-explaination {
  margin-top: 40px;
  margin-right: 50px;
}
@media screen and (max-width: 1024px) {
  .questionnaire-preview-wrapper .question-explaination {
    margin-right: 0px;
  }
}
.questionnaire-preview-wrapper .importance {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
  color: #D3D3D3;
}
.questionnaire-preview-wrapper .importance .rise {
  padding-right: 50px;
}
.questionnaire-preview-wrapper .view-results {
  justify-content: center;
  display: flex;
  margin-bottom: 100px;
}
.questionnaire-preview-wrapper .view-results .view-results-btn {
  width: 675px;
  height: 50px;
  border-radius: 4px;
  background-color: #f26221;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
}
@media screen and (max-width: 1024px) {
  .questionnaire-preview-wrapper .view-results .view-results-btn {
    margin-left: 60px;
  }
}
.questionnaire-preview-wrapper .margin-60 {
  margin-left: 60px;
}

.edit-mode .selected-grade {
  margin-right: 50px;
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.edit-mode .selected-grade button {
  width: 30%;
}
.edit-mode .question-content {
  margin-right: 18px;
}
.edit-mode .preview-edit-save {
  cursor: pointer;
}

.question-explanation-container {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 10px;
  white-space: pre-wrap;
}

.show-topic-definition {
  margin: 10px 60px;
  color: #4e266a;
  cursor: pointer;
}

.topic-definition-wrapper {
  margin: 0px 60px 20px;
  font-size: 14px;
  min-height: 50px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.choose-respondent-model .header {
  border-bottom: none !important;
  display: flex !important;
  justify-content: space-between;
}
.choose-respondent-model .header .close-icon {
  cursor: pointer;
}
.choose-respondent-model .content {
  padding: 3rem !important;
}
.choose-respondent-model .content .respondent-item {
  border: solid 1px #e7e7e7;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
}
.choose-respondent-model .content .respondent-item .stakeholder-name {
  display: flex;
  justify-content: space-around;
}

.read-more-model .header {
  border-bottom: none !important;
  font-size: 23px !important;
  font-weight: 500 !important;
  letter-spacing: -0.4px;
  padding: 50px 50px 0px !important;
  display: flex !important;
  justify-content: space-between;
}
.read-more-model .content {
  padding: 50px !important;
  font-size: 16px !important;
  color: #686868;
}
.read-more-model .close-icon {
  cursor: pointer;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.profile-view-wrapper {
  background-color: #fbfbfb;
}
.profile-view-wrapper .profile-view {
  padding-top: 135px;
  padding-bottom: 135px;
}
.profile-view-wrapper .profile-view .profile-form {
  width: 500px;
  padding: 70px;
  height: 500px;
  margin: 0px 25%;
}
@media screen and (max-width: 1024px) {
  .profile-view-wrapper .profile-view .profile-form {
    margin: 0px 16%;
  }
}
.profile-view-wrapper .profile-view .user-name-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.profile-view-wrapper .profile-view .icon-icn-edit {
  font-size: 18px;
  cursor: pointer;
}
.profile-view-wrapper .profile-view .view-reports {
  color: #3d3d3d;
  font-size: 14px;
  text-decoration: underline;
  padding: 12px 0px;
}
.profile-view-wrapper .profile-view .save-username {
  text-decoration: underline;
  cursor: pointer;
}
.profile-view-wrapper .profile-view .ui.input input {
  border: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0px;
  padding-left: 0px;
}
.profile-view-wrapper .profile-view .user-name {
  font-size: 24px;
  line-height: normal;
  letter-spacing: -1px;
  color: #4b2369;
}
.profile-view-wrapper .profile-view .user-email {
  font-size: 12px;
  letter-spacing: -0.5px;
  color: #3d3d3d;
  padding: 10px 0px 15px;
}
.profile-view-wrapper .profile-view .company-details-container {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.profile-view-wrapper .profile-view .company-details-container .company-header, .profile-view-wrapper .profile-view .company-details-container .phone-header {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #aeaeae;
}
.profile-view-wrapper .profile-view .company-details-container .company-name, .profile-view-wrapper .profile-view .company-details-container .phone-number {
  font-size: 26px;
  line-height: normal;
  padding-top: 15px;
}
.profile-view-wrapper .profile-view .change-password {
  text-align: center;
  padding: 140px 0px 0px;
  cursor: pointer;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.change-password-module-view .content .heading {
  font-size: 30px;
  letter-spacing: -0.5px;
  padding-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .change-password-module-view .content .heading {
    font-size: 25px;
    padding-bottom: 30px;
  }
}
.change-password-module-view .content .btn-wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .change-password-module-view .content .btn-wrapper {
    padding-top: 30px;
  }
}
.change-password-module-view .content .btn-wrapper .submit-button {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.results-wrapper {
  background-color: #fbfbfb;
}
.results-wrapper .height-fill {
  height: -webkit-fill-available;
}
.results-wrapper .padding-50 {
  padding-bottom: 50px;
}
.results-wrapper .heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.results-wrapper .right-header-wrapper {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
.results-wrapper .dropdown-wrapper {
  display: flex;
}
.results-wrapper .version-wrapper, .results-wrapper .legend-select-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.results-wrapper .version-wrapper label, .results-wrapper .legend-select-wrapper label {
  margin-bottom: 10px;
}
.results-wrapper .recharts-layer.recharts-cartesian-axis-tick text {
  font-size: 15px;
}
.results-wrapper .view-other {
  padding: 15px 75px;
  font-size: 16px;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.results-wrapper .heading-results {
  font-size: 39px;
  line-height: 1.03;
  letter-spacing: 1px;
  color: #3d3d3d;
  padding: 25px 0px 15px 0px;
}
@media screen and (max-width: 1024px) {
  .results-wrapper .heading-results {
    font-size: 30px;
  }
}
.results-wrapper .heading-results-line-2 {
  font-size: 25px;
  line-height: 1.03;
  letter-spacing: 1px;
  color: #3d3d3d;
  padding: 0px;
}
@media screen and (max-width: 1024px) {
  .results-wrapper .heading-results-line-2 {
    font-size: 30px;
  }
}
.results-wrapper .matrix-back {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #4e266a;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 0px;
}
.results-wrapper .company-heading-container {
  display: flex;
  padding-top: 10px;
}
.results-wrapper .company-name {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
  margin-bottom: 40px;
  margin-right: 50px;
}
.results-wrapper .marker-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.results-wrapper .results-container {
  display: flex;
  justify-content: space-between;
}
.results-wrapper .ui.basic.table tbody tr, .results-wrapper .ui.table thead th {
  border-bottom: none;
}
.results-wrapper .ui.table tr td {
  border-top: 0px;
}
.results-wrapper .ui.table tbody tr td {
  white-space: pre-wrap;
  max-width: 350px;
}
@media screen and (max-width: 1024px) {
  .results-wrapper .recharts-wrapper {
    width: 400px !important;
    height: 400px !important;
  }
}
.results-wrapper .recharts-wrapper svg {
  width: inherit;
  height: inherit;
}
.results-wrapper .recharts-wrapper .recharts-text.recharts-label {
  pointer-events: none;
}
.results-wrapper .legend-wrapper {
  width: 530px;
}
.results-wrapper .graph-explanation-wrapper {
  font-size: 12px;
  line-height: 1.42;
  letter-spacing: -0.2px;
  width: 515px;
  padding: 30px 0px;
}
@media screen and (max-width: 1024px) {
  .results-wrapper .graph-explanation-wrapper {
    width: 375px;
  }
}
.results-wrapper .graph-explanation-wrapper .stakeholder-perceptions-info, .results-wrapper .graph-explanation-wrapper .influence-on-business-info {
  padding: 0px 0px 20px 76px;
}
.results-wrapper .graph-explanation-wrapper .stakeholder-perceptions-info::before, .results-wrapper .graph-explanation-wrapper .influence-on-business-info::before {
  content: "|";
  padding-right: 10px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.alert-modal-view {
  padding: 30px 30px 20px;
}
.alert-modal-view .header {
  border: none !important;
}
.alert-modal-view .content {
  padding: 20px 30px;
  font-size: 16px;
}
.alert-modal-view .content p {
  margin: 0px;
}
.alert-modal-view .actions {
  border: none !important;
  background-color: #ffffff !important;
  display: flex;
  justify-content: flex-end;
}

.alert-block-modal .header {
  display: flex !important;
  align-items: center;
  height: 50px;
  color: red !important;
}
.alert-block-modal .header .icon-alert-icon {
  padding-right: 20px;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.stakeholder-interviews-heading {
  margin: 0px auto;
  font-size: 36px;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: #3d3d3d;
  padding-top: 50px;
}
@media screen and (max-width: 1024px) {
  .stakeholder-interviews-heading {
    font-size: 30px;
  }
}

.stakeholder-interviews-wrapper {
  text-align: center;
  padding: 50px 0px 300px 0px;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item {
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item span {
  font-size: 18px;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item .stakeholder-interviews-file-info {
  padding: 15px 30px;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item .stakeholder-interviews-file-info .filename-info {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  word-break: break-all;
  text-align: left;
  padding: 5px 0px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item .stakeholder-interviews-file-info .uploadedby-info {
  font-size: 12px;
  color: #686868;
  float: left;
}
.stakeholder-interviews-wrapper .stakeholder-interviews-item .upload-date {
  color: #686868;
  font-size: 12px;
  text-align: right;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.value-chain-boundaries-heading {
  margin: 0px auto;
  font-size: 36px;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: #3d3d3d;
  padding-top: 50px;
}
@media screen and (max-width: 1024px) {
  .value-chain-boundaries-heading {
    font-size: 30px;
  }
}

.value-chain-boundaries-wrapper {
  text-align: center;
  padding: 50px 0px 300px 0px;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item {
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item span {
  font-size: 18px;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item .value-chain-boundaries-file-info {
  padding: 15px 30px;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item .value-chain-boundaries-file-info .filename-info {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  word-break: break-all;
  text-align: left;
  padding: 5px 0px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item .value-chain-boundaries-file-info .uploadedby-info {
  font-size: 12px;
  color: #686868;
  float: left;
}
.value-chain-boundaries-wrapper .value-chain-boundaries-item .upload-date {
  color: #686868;
  font-size: 12px;
  text-align: right;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.review-stakeholder-configs-view {
  padding: 30px 30px 20px;
}
.review-stakeholder-configs-view .header {
  height: fit-content;
  border: 1px solid !important;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.review-stakeholder-configs-view .header .icon-alert-icon {
  font-size: 36px;
  padding: 0px 20px 0px 0px;
}
.review-stakeholder-configs-view .header p {
  margin: 0px;
}
.review-stakeholder-configs-view .header .heading-content p:nth-child(2) {
  color: red;
  font-size: 16px;
}
.review-stakeholder-configs-view .content table {
  background-color: lightyellow !important;
}
.review-stakeholder-configs-view .content .stakeholder-configurations {
  padding: 0px 0px 30px;
}
.review-stakeholder-configs-view .content .stakeholder-configurations .heading {
  font-size: 16px;
}
.review-stakeholder-configs-view .content .heading-wrapper-modal {
  display: flex;
  justify-content: space-between;
}
.review-stakeholder-configs-view .actions {
  border: none !important;
  background-color: #ffffff !important;
  display: flex;
  justify-content: space-between;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.review-businessCriteria-configs-view {
  padding: 30px 30px 20px;
}
.review-businessCriteria-configs-view .header {
  height: fit-content;
  border: 1px solid !important;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.review-businessCriteria-configs-view .header .icon-alert-icon {
  font-size: 36px;
  padding: 0px 20px 0px 0px;
}
.review-businessCriteria-configs-view .header p {
  margin: 0px;
}
.review-businessCriteria-configs-view .header .heading-content p:nth-child(2) {
  color: red;
  font-size: 16px;
}
.review-businessCriteria-configs-view .content table {
  background-color: lightyellow !important;
}
.review-businessCriteria-configs-view .content .business-criteria-configurations {
  padding: 0px 0px 30px;
}
.review-businessCriteria-configs-view .content .business-criteria-configurations .heading {
  font-size: 16px;
}
.review-businessCriteria-configs-view .content .heading-wrapper-modal {
  display: flex;
  justify-content: space-between;
}
.review-businessCriteria-configs-view .actions {
  border: none !important;
  background-color: #ffffff !important;
  display: flex;
  justify-content: space-between;
}

.app-btn {
  width: 130px;
  height: 50px;
  border-radius: 4px;
  background-color: #2f3d51;
  border: solid 1px #2f3d51;
}

.strategy-reports-file-heading {
  margin: 0px auto;
  font-size: 36px;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: #3d3d3d;
  padding-top: 50px;
}
@media screen and (max-width: 1024px) {
  .strategy-reports-file-heading {
    font-size: 30px;
  }
}

.strategy-reports-file-wrapper {
  text-align: center;
  padding: 50px 0px 300px 0px;
}
.strategy-reports-file-wrapper .strategy-reports-file-item {
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.strategy-reports-file-wrapper .strategy-reports-file-item span {
  font-size: 18px;
}
.strategy-reports-file-wrapper .strategy-reports-file-item .strategy-reports-file-file-info {
  padding: 15px 30px;
}
.strategy-reports-file-wrapper .strategy-reports-file-item .strategy-reports-file-file-info .filename-info {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  word-break: break-all;
  text-align: left;
  padding: 5px 0px;
  letter-spacing: -0.3px;
  color: #3d3d3d;
}
.strategy-reports-file-wrapper .strategy-reports-file-item .strategy-reports-file-file-info .uploadedby-info {
  font-size: 12px;
  color: #686868;
  float: left;
}
.strategy-reports-file-wrapper .strategy-reports-file-item .upload-date {
  color: #686868;
  font-size: 12px;
  text-align: right;
}

@font-face {
  font-family: QuickSandLight;
  src: url("../assets/fonts/app-fonts/Quicksand-Light.ttf");
}
@font-face {
  font-family: QuickSandRegular;
  src: url("../assets/fonts/app-fonts/Quicksand-Regular.ttf");
}
@font-face {
  font-family: QuickSandMedium;
  src: url("../assets/fonts/app-fonts/Quicksand-Medium.ttf");
}
@font-face {
  font-family: QuickSandBold;
  src: url("../assets/fonts/app-fonts/Quicksand-Bold.ttf");
}
body {
  overflow-x: auto;
}

