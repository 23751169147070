@import "App.scss";
@import "colors.scss";
@import "variables.scss";

@import "iconmoon-fonts.scss";

@import "header.scss";
@import "footer.scss";
@import "auth-module.scss";
@import "common.scss";
@import "override.scss";
@import "buttons.scss";
@import "email-verified.scss";

@import "landing-page.scss";
@import "analysis-types.scss";
@import "stakeholders-insights.scss";
@import "business-ability.scss";
@import "set-password-view.scss";
@import "questionnaire.scss";

@import "analyse-drivers.scss";
@import "list-of-topics.scss";
@import "question-template.scss";
@import "different-category-modal.scss";
@import "edit-weightage-modal.scss";
@import "questionnaire-preview.scss";
@import "input-screen-modal.scss";
@import "read-more-modal.scss";
@import "profile-page-view.scss";
@import "change-password-view.scss";
@import "strategy-reports.scss";
@import "alert-modal.scss";
@import "stakeholder-interviews.scss";
@import "value-chain-boundaries.scss";
@import "review-stakeholder-configurations.scss";
@import "review-business-criterias-configurations.scss";
@import "strategy-reports-file.scss";

@font-face {
  font-family: QuickSandLight;
  src: url('../assets/fonts/app-fonts/Quicksand-Light.ttf');
}
@font-face {
  font-family: QuickSandRegular;
  src: url('../assets/fonts/app-fonts/Quicksand-Regular.ttf');
}
@font-face {
  font-family: QuickSandMedium;
  src: url('../assets/fonts/app-fonts/Quicksand-Medium.ttf');
}
@font-face {
  font-family: QuickSandBold;
  src: url('../assets/fonts/app-fonts/Quicksand-Bold.ttf');
}

body {
  overflow-x: auto;
}