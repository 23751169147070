@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.select-stake-holder-wrapper {
  width: 55% !important;
  .small-header-container {
    display: flex;
    justify-content: space-between;
    .small-header {
      padding-top: $default-padding * 5;
      padding-bottom: $default-padding * 2;
      font-size: $default-font-size + 4;
      letter-spacing: 1px;
      color: #4e266a;
      @media screen and (max-width: 1024px){
        font-size: $default-font-size+2;
      }
    }
    .toggle-questionnaire {
      padding-top: 50px;
      padding-bottom: 20px;
      font-size: 14px;
      letter-spacing: 1px;
      color: #4e266a;
      text-decoration: underline;
      @media screen and (max-width: 1024px){
        font-size: $default-font-size+2;
      }
    }
  }
  .large-header-container {
    padding-bottom: $default-padding * 3;
  }
  .choose-respondent {
    padding: 10px 0px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #4e266a;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
  .large-header {
    font-size: $default-font-size * 3;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: #3d3d3d;
    @media screen and (max-width: 1024px){
      font-size: $default-font-size * 2;
    }
  }
  .ui.input.fluid {
    margin-bottom: $default-padding * 4;
  }
  .form-footer {
    margin-bottom: $default-padding * 3;
  }
  .questionnaire-btn {
    width: 100%;
    height: 50px;
    border-radius: 4px;
  }
}
.ui.form {
  height: 100%;
  .grouped.fields {
    .field {
      display: flex;
      justify-content: space-between;
      font-size: $default-font-size * 3;
      @media screen and (max-width: 1024px){
        font-size: $default-font-size * 2;
      }
      label {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        cursor: pointer;
      }
      input {
        width: 25px;
        cursor: pointer;
        @media screen and (max-width: 1024px){
          width: 20px;
        }
      }
    }
  }
}