@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.header-view{
  @media print  {
    position: relative;
  }
  width: 100%;
  position: fixed;
  top:0;
  background-color: $white;
  padding: $default-padding $default-padding - 5px;
  box-shadow: 2px 3.5px 9.5px 0.5px $header-shadow-color;
  z-index: 1000;
  .header-wrapper{
    height: $default-font-size*7;
    @include align-item(flex, flex-start, center, row);
  
    .left-section-wrapper{
      width: 50%;
      .left-section{
        text-align: left;
        .brand-logo-wrapper{
          padding-top: $default-padding - 5px;
          padding-bottom: $default-padding - 5px;
          img{
            width: 100px;
            height: auto;
            cursor: pointer;
          }
        }
      }
    }
    .right-section-wrapper{
      width: 50%;
      .right-section{
        .logged-out-state-wrapper, .logged-in-state-wrapper{
          @include align-item(flex, flex-end, center, row);
          .link{
            min-width: 100px;
            cursor: pointer;
            @include set-font(QuickSandRegular, $default-font-size+4, $boulder, normal, right, normal);
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+6;
            }
          }
        }
      }
    }
  }
}