@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.strategy-reports-file-heading {
  margin: 0px auto;
  font-size: $default-font-size+26;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: #3d3d3d;
  padding-top: $default-padding*5;
  @media screen and (max-width: 1024px){
    font-size: $default-font-size * 3;
  }
}
.strategy-reports-file-wrapper {
  text-align: center;
  padding: $default-padding*5 0px $default-padding*30 0px;
  .strategy-reports-file-item {
    padding: 0px $default-padding*3;
    margin: auto;
    margin-bottom: 30px;
    box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    span {
      font-size: $default-font-size+8;
    }
    .strategy-reports-file-file-info {
      padding: $default-padding+5 $default-padding*3;
      .filename-info{
        font-weight: 500;
        font-size: $default-font-size+8;
        width: 100%;
        word-break: break-all;
        text-align: left;
        padding: 5px 0px;
        letter-spacing: -0.3px;
        color: #3d3d3d;
      }
      .uploadedby-info {
        font-size: $default-font-size+2;
        color: #686868;
        float: left;
      }
    }
    .upload-date {
      color: #686868;
      font-size: 12px;
      text-align: right;
    }
  }
}