@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.auth-module-view{
  width: 520px !important;
  @mixin modal-heading{
    letter-spacing: -0.5px;
    text-transform: capitalize;
    @include set-font(QuickSandMedium, $default-font-size*2, $pickled-bluewood, normal, left, normal);
    @media screen and (min-width: 1024px){
      font-size: $default-font-size*3;
    }
  }
  @mixin form-para-text{
    @include set-font(QuickSandMedium, $default-font-size+4, $pickled-bluewood, normal, left, 500);
  }
  @mixin view-change-link{
    padding-top: $default-padding*3;
    cursor: pointer;
    @include set-font(QuickSandMedium, $default-font-size+4, $pickled-bluewood, normal, center, 500);
    @media screen and (min-width: 1024px){
      padding-top: $default-padding*5;
    }
  }
  .content{
    padding: $default-padding*3 !important;
    @media screen and (min-width: 1024px){
      padding: $default-padding*5 !important;
      padding-bottom: $default-padding*3 !important;
    }
    
    /* start :: login view styles */
    .login-view{
      .heading{
        padding-bottom: $default-padding*3;
        @include modal-heading;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*5;
        }
      }
      .ui.form{
        .btn-wrapper{
          margin-top: $default-padding*3;
          @include align-item(flex, space-between, center, row);
          @media screen and (min-width: 1024px){
            margin-top: $default-padding*5;
          }
          .app-btn{
            @include app-btn(auto, 50px, $pickled-bluewood, $pickled-bluewood);
          }
          .forgot-password-link{
            cursor: pointer;
            @include set-font(QuickSandMedium, $default-font-size+4, $pickled-bluewood, normal, left, 500);
          }
        }
        .sign-up-here-link{
          @include view-change-link;
        }
      }
    }
    /* end :: login view styles */
    
    /* start :: sign up view styles */
    .register-view{
      .heading{
        padding-bottom: $default-padding*3;
        @include modal-heading;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*5;
        }
      }
      .ui.form{
        .checkbox-wrapper{
          margin-top: $default-margin*2;
          .ui.checkbox{
            @include set-font(QuickSandRegular, $default-font-size+2 !important, $shuttle-gray, normal, left, normal);
          }
        }
        .btn-wrapper{
          margin-top: $default-padding*3;
          @media screen and (min-width: 1024px){
            margin-top: $default-padding*4;
          }
          .app-btn{
            @include app-btn(130px, 50px, $pickled-bluewood, $pickled-bluewood);
          }
        }
        .login-here-link{
          @include view-change-link;
        }
      }
    }
    /* end :: sign up view styles */
  
    /* start :: forgot password view styles */
    .forgot-password-view{
      .heading{
        @include modal-heading;
      }
      .form-text{
        padding-top: $default-padding+2;
        padding-bottom: $default-padding*3;
        @include form-para-text;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*5;
        }
      }
      .ui.form{
        .btn-wrapper{
          margin-top: $default-padding*3;
          @include align-item(flex, space-between, center, row);
          @media screen and (min-width: 1024px){
            margin-top: $default-padding*5;
          }
          .app-btn{
            @include app-btn(130px, 50px, $pickled-bluewood, $pickled-bluewood);
          }
          .login-link{
            cursor: pointer;
            @include set-font(QuickSandMedium, $default-font-size+4, $pickled-bluewood, normal, left, 500);
          }
        }
      }
    }
    /* end :: forgot password view styles */
    
    /* start :: password reset link sent view styles */
    .password-reset-link-sent-view, .register-success-view{
      .heading{
        @include modal-heading;
        padding-bottom: $default-padding*3;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*4;
        }
      }
      .form-text{
        @include form-para-text;
        padding-bottom: $default-padding*2;
        @media screen and (min-width: 1024px){
          padding-bottom: $default-padding*4;
        }
      }
      .btn-wrapper{
        margin-top: $default-padding*3;
        @media screen and (min-width: 1024px){
          margin-top: $default-padding*4;
        }
        .app-btn{
          @include app-btn(140px, 50px, $pickled-bluewood, $pickled-bluewood);
        }
      }
    }
    /* end :: password reset link sent view styles */
    
    /* start :: login view styles */
    .empty-modal-view{
    
    }
    /* end :: login view styles */
    
  }
  
}