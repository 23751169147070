@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.edit-weightage-model {
  .header {
    font-size: $default-font-size + 13 !important;
    letter-spacing: -0.4px;
    border-bottom: none !important;
    padding: $default-padding * 5 !important;
    padding-bottom: $default-padding * 4 !important;
    display: flex !important;
    justify-content: space-between;
    .close-icon {
      cursor: pointer;
    }
  }
  .content {
    padding-bottom: $default-padding * 5 !important;
    form {
      padding: 0px $default-padding * 3;
      .save-weightage {
        margin-top: 50px !important;
      }
    }
  }
}