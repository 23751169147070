@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.questionnaire-preview-wrapper {
  .ui.container {
    width: 65%;
    @media print  {
      width: 100%;
    }
  }
  .heading-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .print-link {
    font-size: 18px;
  }
  .heading {
    padding-top: 50px;
    font-size: 39px;
    line-height: 1.03;
    letter-spacing: 1px;
    @media screen and (max-width: 1024px){
      font-size: 30px;
    }
  }
  .back-to-questionnaire {
    font-size: 15px;
    text-decoration: underline;
    color: #3d3d3d;
    padding: 20px 0px;
  }
  .questionnaire-list-preview {
    padding-top: 100px;
  }
  .respondant-container {
    font-size: 22px;
  }
  .category-name {
    font-size: 22px;
    line-height: normal;
    letter-spacing: normal;
    color: #3d3d3d;
    margin-bottom: 30px;
  }
  .questionnaire-item {
    margin-bottom: 100px;
    .category-weight-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .weightage {
        cursor: pointer;
        color: $link-color;
        @media print {
          display: none;
        }
      }
    }
  }
  .question-content {
    margin: 0px;
    align-items: center;
    .question-number {
      border-radius: 4px;
      background-color: #b7caff;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0px;
      justify-content: space-around;
    }
    .topic-name {
      padding-left: 30px !important;
      font-size: 16px;
      line-height: 2.75;
    }
    .icon-icn-edit {
      cursor: pointer;
    }
  }
  .preview-btn-grade {
    width: 240px;
    height: 50px;
    border-radius: 4px;
    margin-top: 15px;
    color: #ffffff;
    @media print {
      font-size: 18px;
    }
  }
  .orange-btn {
    background-color: #f5a623;
    border: solid 1px #f5a623;
    //color: #f5a623;
  }
  .green-btn {
    background-color: #7ed321;
    border: solid 1px #7ed321;
    //color: #7ed321;
  }
  .red-btn {
    background-color: #ff5066;
    border: solid 1px #ff5066;
    //color: #ff5066;
  }
  .question-explaination {
    margin-top: 40px;
    margin-right: 50px;
    @media screen and (max-width: 1024px){
      margin-right: 0px;
    }
  }
  .importance {
    display: flex;
    margin-top: 15px;
    font-size: 14px;
    color: #D3D3D3;
    .rise {
      padding-right: 50px;
    }
  }
  .view-results {
    justify-content: center;
    display: flex;
    margin-bottom: 100px;
    .view-results-btn {
      width: 675px;
      height: 50px;
      border-radius: 4px;
      background-color: #f26221;
      font-size: 18px;
      color: #ffffff;
      margin: 0px;
      @media screen and (max-width: 1024px){
        margin-left: 60px;
      }
    }
  }
  .margin-60 {
    margin-left: 60px;
  }
}

.edit-mode {
  .selected-grade {
    margin-right: 50px;
    display: flex;
    height: 50px;
    justify-content: space-between;
    button {
      width: 30%;
    }
  }
  .question-content {
    margin-right: 18px;
  }
  .preview-edit-save {
    cursor: pointer;
  }
}

.question-explanation-container{
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 10px;
  white-space: pre-wrap;
}

.show-topic-definition {
  margin: 10px 60px;
  color: $link-color;
  cursor: pointer;
}
.topic-definition-wrapper {
  margin: 0px 60px 20px;
  font-size: 14px;
  min-height: 50px;
}
