@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.alert-modal-view {
  padding: $default-padding * 3 $default-padding * 3 $default-padding * 2;
  .header {
    border: none !important;
  }
  .content {
    padding: 20px 30px;
    font-size: $default-font-size + 6;
    p {
      margin: 0px;
    }
  }
  .actions {
    border: none !important;
    background-color: #ffffff !important;
    display: flex;
    justify-content: flex-end;
  }
}

.alert-block-modal {
  .header {
    display: flex !important;
    align-items: center;
    height: 50px;
    color: $default-text-color !important;
    .icon-alert-icon {
      padding-right: 20px;
    }
  }
}