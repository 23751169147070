// COLORS
$white: #FFFFFF;
$bossanova: #3B214E;
$mardi-gras: #1e0032;
$alabaster: #F8F8F8;
$mako: #43484d;
$boulder: #747474;
$mine-shaft: #3d3d3d;
$concrete: #f2f2f2;
$grape: #3b1f4f;
$rum: #7b618e;
$pickled-bluewood: #2f3d51;
$shuttle-gray: #686b78;
$wild-water-melon: #ff5066;
$flamingo: #f26221;
$dove-gray: #686868;
$silver-chalice: #aeaeae;
$jacarta: #4e266a;
$mercury: #e7e7e7;
$lima: #7ed321;
$green: #2adb13;

// VARIABLES
$app-theme-color: $bossanova;
$footer-background: $grape;
$default-text-color: red;
$header-shadow-color: rgba(107, 106, 128, 0.1);
$input-place-holder-color: $shuttle-gray;
$error-color: $wild-water-melon;
$link-color: $jacarta;