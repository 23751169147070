@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.results-wrapper {
  .height-fill {
    height: -webkit-fill-available;
  }
  background-color: #fbfbfb;
  .padding-50 {
    padding-bottom: 50px;
  }
  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-header-wrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .dropdown-wrapper {
    display: flex;
  }
  .version-wrapper, .legend-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    label {
      margin-bottom: 10px;
    }
  }
  .recharts-layer.recharts-cartesian-axis-tick {
    text {
      font-size: 15px;
    }
  }
  .view-other {
    padding: 15px 75px;
    font-size: 16px;
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .heading-results {
    font-size: 39px;
    line-height: 1.03;
    letter-spacing: 1px;
    color: #3d3d3d;
    padding: 25px 0px 15px 0px;
    @media screen and (max-width: 1024px){
      font-size: 30px;
    }
  }
  .heading-results-line-2 {
    font-size: 25px;
    line-height: 1.03;
    letter-spacing: 1px;
    color: #3d3d3d;
    @media screen and (max-width: 1024px){
      font-size: 30px;
    }
    padding: 0px;
  }
  .matrix-back {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #4e266a;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 0px;
  }
  .company-heading-container {
    display: flex;
    padding-top: 10px;
  }
  .company-name {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #3d3d3d;
    margin-bottom: 40px;
    margin-right: 50px;
  }
  .marker-circle {
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  .results-container {
    display: flex;
    justify-content: space-between;
  }
  .ui.basic.table tbody tr, .ui.table thead th {
    border-bottom: none;
  }
  .ui.table tr td { 
    border-top: 0px; 
  }
  .ui.table tbody tr td {
    white-space: pre-wrap;
    max-width: 350px;
  }
  .recharts-wrapper {
    @media screen and (max-width: 1024px){
      width: 400px !important;
      height: 400px !important;
    }
    svg {
      width: inherit;
      height: inherit;
    }
    .recharts-text.recharts-label {
      pointer-events: none;
    }
  }
  .legend-wrapper {
    width: 530px;
  }
  .graph-explanation-wrapper {
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.2px;
    width: 515px;
    padding: 30px 0px;
    @media screen and (max-width: 1024px){
      width: 375px;
    }
    .stakeholder-perceptions-info, .influence-on-business-info {
      padding: 0px 0px 20px 76px;
      &::before {
        content: "|";
        padding-right: 10px;
      }
    }
  }
}