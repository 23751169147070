@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.ui.button.app-btn{
  width: 160px;
  height: 34px;
  border-radius: 4px;
  @media screen and (min-width: 1024px){
    width: 200px;
    height: 40px;
  }
}

.ui.button.purple-solid-btn{
  background-color: $rum;
  @include set-font(QuickSandMedium, $default-font-size+2, $white, normal, center, normal);
}

.ui.button.orange-solid-btn{
  background-color: $flamingo;
  @include set-font(QuickSandMedium, $default-font-size+2, $white, normal, center, normal);
}