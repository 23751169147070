@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.email-verify-container {
  padding: $default-padding * 6 0px;
  display: flex !important;
  justify-content: center;
  .email-verify-wrapper {
    width: 70%;
    button {
      font-size: 20px;
    }
    .message-content-wrapper {
      display: flex;
      align-items: center;
      text-align: left;
      margin: $default-margin * 5;
      img {
        width: 60px;
        margin-right: 20px;
      }
      .icon-alert-icon {
        font-size: $default-font-size + 45;
        padding: $default-padding * 3;
      }
      .message-content {
        font-size: $default-font-size * 3;
        div {
          line-height: 1em;
        }
      }
      .message-content div:nth-child(1) {
        color: $default-text-color;
      }
    }
  }

}