@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.unordered-list{
  padding-left: $default-padding * 2;
  .list-item{
    list-style-position: outside;
  }
}

.relative-position{
  position: relative;
}

.clear-opacity{
  opacity: 1 !important;
}

.error-message-view{
  padding-top: ($default-padding - 5);
  margin-bottom: ($default-margin - 5);
  @include set-font(QuickSandLight, $default-font-size+2, $error-color, normal, left, normal);
  @media screen and (min-width: 768px){
    font-size: $default-font-size+4;
  }
}

.success-message-view{
  padding-top: ($default-padding - 5);
  margin-bottom: ($default-margin - 5);
  @include set-font(QuickSandLight, $default-font-size+2, $green, normal, left, normal);
  @media screen and (min-width: 768px){
    font-size: $default-font-size+4;
  }
}

.opacity-btn {
  opacity: 0.5;
}
.empty-state-view{
  height: 200px;
  @include set-font(QuickSandMedium, $default-font-size+4, $shuttle-gray, normal, left, normal);
  @include align-item(flex, center, center, row);
}

.up-arrow, .down-arrow {
  padding-left: $default-padding;
}

.opacity-preview {
  pointer-events: none;
  opacity: 0.3;
}
.reduced-opacity-disabled {
  opacity: 0.3;
}

.empty-div {
  margin: 50%
}

.cursor-not-allowed {
  pointer-events: none;
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bolder;
}

.selected-perception {
  color: #3d3d3d;
}

.print-link {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: $link-color;
  margin-bottom: 40px;
  margin-left: 50px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}