@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.footer-view{
  width: 100%;
  background-color: $footer-background;
  padding: $default-padding*5 0px 0px 0px;
  
  .footer-content-wrapper{
    width: 720px;
    @include align-item(flex, flex-start, flex-start, row);
    @mixin heading{
      padding-bottom: $default-padding*2;
      @include set-font(QuickSandMedium, $default-font-size+6, $white, normal, left, 500);
      @media screen and (min-width: 1024px){
        font-size: $default-font-size*2;
      }
    }
    @mixin content{
      @include set-font(QuickSandRegular, $default-font-size+4, $white, $default-line-height*2 + 5, left, normal);
    }
    @media screen and (min-width: 1024px){
      width: 1000px;
    }
    .left-section{
      width: 50%;
      .left-section-content{
        @include align-item(flex, space-between, flex-start, row);
        .our-location-section{
          width: 50%;
          max-width: 200px;
          .heading{
            @include heading;
          }
          .content{
            @include content;
          }
        }
        .get-in-touch-section{
          width: 50%;
          .heading{
            @include heading;
          }
          .content{
            @include content;
          }
          .contact-number{
            margin-top: $default-margin*3;
            @include set-font(QuickSandRegular, $default-font-size*2, $white, normal, left, normal);
            
            @media screen and (min-width: 1024px){
              font-size: $default-line-height*3;
            }
          }
        }
      }
    }
    .right-section{
      width: 50%;
      .right-section-content{
        @include align-item(flex, space-between, flex-start, row);
        .about-us-section{
          width: 70%;
          max-width: 220px;
          text-align: left;
          margin-left: $default-margin*3;
          @media screen and (min-width: 1024px){
            max-width: 300px;
          }
          .heading{
            @include heading;
          }
          .content{
            @include content;
          }
          .social-link-wrapper{
            margin-top: $default-margin*3;
            .icon{
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $white;
              &:before{
                font-size: $default-font-size*2;
                display: block;
                margin-top: $default-margin;
                color: grey;
              }
            }
          }
        }
        .brand-logo-wrapper{
          width: 30%;
          margin-top: $default-margin*4;
          img{
            width: 80px;
            height: auto;
          }
        }
      }
    }
  }
  .all-right-reserved-section{
    margin-top: $default-padding*3;
    padding: $default-padding 0px;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    .copy-right-text-wrapper{
      width: 720px;
      @include set-font(QuickSandRegular, $default-font-size, $white, normal, left, normal);
      
      @media screen and (min-width: 1024px){
        width: 1000px;
      }
      .icon{
        &:before{
          color: $white;
        }
      }
    }
  }
}