@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.business-ability-view{
  .loading-wrapper {
    position: fixed;
  }
  .hero-banner-wrapper{
    position: relative;
    .hero-banner{
      width: 100%;
      height: 200px;
      position: relative;
      background-color: $flamingo;
      
      .banner-content-wrapper{
        padding: $default-padding*2 0px;
        
        @media screen and (min-width: 1024px){
          padding: $default-padding*3 0px;
        }
        
        .banner-heading{
          padding-bottom: $default-padding*2;
          @media screen and (min-width: 1024px){
            padding-bottom: $default-padding*2;
          }
          .small-heading{
            @include set-font(QuickSandLight, $default-font-size+4, $white, normal, left, normal);
            margin-bottom: $default-margin+5;
          }
          .big-heading{
            text-transform: uppercase;
            @include set-font(QuickSandMedium, $default-font-size*2, $white, normal, left, normal);
            @media screen and (min-width: 1024px){
              font-size: $default-font-size*3;
            }
          }
        }
        .banner-content-para{
          max-width: 640px;
          @include set-font(QuickSandLight, $default-font-size+4, $white, $default-line-height*2, left, normal);
          @media screen and (min-width: 1024px){
            line-height: $default-line-height*3;
          }
        }
        
      }
      
    }
  }
  
  .breadcrumb-wrapper{
    background-color: $white;
    padding: $default-padding*2;
    border: solid 1px $mercury;
    .ui.container{
      display: flex;
      justify-content: space-between;
      .ui.large.breadcrumb{
        text-transform: uppercase;
        @include set-font(QuickSandMedium, $default-font-size+4, $silver-chalice, normal, left, normal);
        a{
          color:  $silver-chalice;
        }
        .divider{
          margin: 0px $default-padding*3;
        }
        .active.section{
          color: $jacarta;
        }
      }
      .print-link{
        margin: 0px;
      }
    }
  }
  
  .main-content-wrapper{
    .business-ability-container{
      @include align-item(flex, flex-start, flex-start, row);
      .left-side-panel{
        width: 30%;
        padding-top: $default-padding*3;
        position: relative;
        @include align-item(flex, flex-start, flex-start, row);
        @media screen and (min-width: 1024px){
          padding-top: $default-padding*5;
        }
        .time-line-bar{
          height: 65%;
          border-left: 1px solid $mercury;
          position: absolute;
          top: 80px;
          left: 15px;
          z-index: 1;
          @media screen and (min-width: 1024px){
            top: 90px;
            left: 20px;
          }
        }
        .business-ability-steps-wrapper{
          @mixin heading{
            @include set-font(QuickSandMedium, $default-font-size+4, $silver-chalice, normal, left, normal);
            padding-bottom: $default-padding;
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+8;
            }
          }
          @mixin sub-heading{
            @include set-font(QuickSandRegular, $default-font-size+2, $mine-shaft, normal, left, normal);
            @media screen and (min-width: 1024px){
              font-size: $default-font-size+4;
            }
          }
          .business-ability-step{
            padding-bottom: $default-padding*10;
            padding-right: $default-padding*2;
            @include align-item(flex, flex-start, center, row);
            @media screen and (min-width: 1024px){
              padding-bottom: $default-padding*12;
            }
            .icon-wrapper{
              width: 20%;
              z-index: 10;
              .icon{
                width: 30px;
                height: 30px;
                border: 2px solid $mercury;
                border-radius: 50%;
                background-color: $white;
                padding: ($default-padding - 6) $default-padding;
                @include set-font(QuickSandMedium, $default-font-size+4, $mercury, normal, left, normal);
                @media screen and (min-width: 1024px){
                  width: 40px;
                  height: 40px;
                  font-size: $default-font-size*2;
                  padding: ($default-padding - 4) ($default-padding + 3);
                }
              }
              .active{
                border-color: $jacarta;
                color: $jacarta;
              }
              .complete-icon{
                width: 30px;
                height: auto;
                @media screen and (min-width: 1024px){
                  width: 40px;
                }
              }
            }
            .content-wrapper{
              width: 80%;
              .heading{
                @include heading;
              }
              .sub-heading{
                @include sub-heading;
              }
              .active{
                color: $jacarta;
              }
            }
          }
        }
      }
      .right-side-panel{
        width: 70%;
        min-height: 500px;
        padding: $default-padding*3;
        padding-right: 0px;
        border-left: 1px solid $mercury;
        @media screen and (min-width: 1024px){
          padding: $default-padding*5 0px $default-padding*5 $default-padding*3;
        }

        .step-one-view {
          @media screen and (min-width: 1024px){
            width: 101%;
          }
        }
        
        .step-one-view, .step-two-view, .step-three-view{
          .back-btn-wrapper{
            margin-bottom: ($default-margin*2)+5;
            .back-btn{
              width: fit-content;
              cursor: pointer;
              @include align-item(flex, flex-start, center, row);
              .icon-wrapper{
                margin-right: $default-margin;
              }
              .btn-text{
                @include set-font(QuickSandMedium, $default-font-size+4, $silver-chalice, normal, left, normal);
                letter-spacing: -0.2px;
              }
            }
          }
          .view-heading-wrapper{
            margin-bottom: $default-margin*3;
            padding-bottom: $default-padding*2;
            border-bottom: 1px solid $mercury;
            .heading{
              @include set-font(QuickSandMedium, $default-font-size+4, $mine-shaft, normal, left, normal);
              @media screen and (min-width: 1024px){
                font-size: $default-font-size+8;
              }
            }
          }
          .add-business-criteria-form-view{
            margin-bottom: $default-padding*4;
            .ui.form .field{
              .business-criteria-name{
                input{
                  border-left: 0px;
                  border-right: 0px;
                  border-top: 0px;
                  border-radius: 0px;
                  font-size: $default-font-size+3;
                }
                input[placeholder] {
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .step-one-form-wrapper, .step-two-form-wrapper, .step-three-form-wrapper{
            .ui.form {
              .field {
                margin-bottom: $default-margin*2;
                .business-criteria-name {
                  @include set-font(QuickSandMedium, $default-font-size+4, $mine-shaft, normal, left, normal);
                }
                .business-criteria-note {
                  @include set-font(QuickSandMedium, $default-font-size+4, rgba(61, 61, 61, 0.5), normal, left, normal);
                }
                .icon-wrapper {
                  font-size: $default-font-size*2;
                  color: $mercury;
                  .icon{
                    cursor: pointer;
                  }
                }
                .strategy-period-select {
                  border: 1px solid #e7e7e7;
                  padding: 15px;
                  border-radius: 4px;
                  cursor: pointer;

                  height: 50px;
                  letter-spacing: 1px;
                  @include set-font(QuickSandMedium, $default-font-size+6, $input-place-holder-color, normal, left, normal);
                }
                .strategy-period-select.selected {
                  background-color: #e7e7e7;
                }
              }
              .other-startegy-period {
                padding: $default-padding;
                font-size: $default-font-size+6;
                color: darkslateblue;
                text-decoration: underline;
                margin-top: 20px;
                cursor: pointer;
              }
              .error-wrapper{
              
              }
              .form-footer{
                border-top: 1px solid $mercury;
                margin-top: $default-margin*8;
                .ui.button{
                  margin-top: $default-margin*3;
                }
              }
            }
          }
        }
      }
    }
  }
}