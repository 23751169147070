@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.choose-respondent-model {
  .header {
    border-bottom: none !important;
    display: flex !important;
    justify-content: space-between;
    .close-icon {
      cursor: pointer;
    }
  }
  .content {
    padding: 3rem !important;
    .respondent-item {
      border: solid 1px #e7e7e7;
      border-radius: 4px;
      margin-bottom: 20px;
      cursor: pointer;
      .stakeholder-name {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}