.read-more-model {
  .header{
    border-bottom: none !important;
    font-size: 23px !important;
    font-weight: 500 !important;
    letter-spacing: -0.4px;
    padding: 50px 50px 0px !important;
    display: flex !important;
    justify-content: space-between;
  }
  .content {
    padding: 50px !important;
    font-size: 16px !important;
    color: #686868;
  }
  .close-icon {
    cursor: pointer;
  }
}