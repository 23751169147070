@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.set-password-view-container{
  height: 500px;
  @include align-item(flex, center, center, row);
  @mixin modal-heading{
    letter-spacing: -0.5px;
    text-transform: capitalize;
    @include set-font(QuickSandMedium, $default-font-size*2, $pickled-bluewood, normal, left, normal);
    @media screen and (min-width: 1024px){
      font-size: $default-font-size*3;
    }
  }
  .set-password-view{
    width: 500px;
    padding: $default-padding*5;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
    .heading{
      padding-bottom: $default-padding*3;
      @include modal-heading;
      @media screen and (min-width: 1024px){
        padding-bottom: $default-padding*5;
      }
    }
    .ui.form{
      .btn-wrapper{
        margin-top: $default-padding*3;
        @include align-item(flex, space-between, center, row);
        @media screen and (min-width: 1024px){
          margin-top: $default-padding*5;
        }
        .app-btn{
          @include app-btn(130px, 50px, $pickled-bluewood, $pickled-bluewood);
        }
      }
    }
  }
  .password-set-success-view{
    .heading{
      padding-bottom: $default-padding*3;
      @include modal-heading;
      @media screen and (min-width: 1024px){
        padding-bottom: $default-padding*5;
      }
    }
    .form-text{
      @include set-font(QuickSandMedium, $default-font-size+4, $pickled-bluewood, normal, left, 500);
      padding-bottom: $default-padding*2;
      @media screen and (min-width: 1024px){
        padding-bottom: $default-padding*4;
      }
    }
    .btn-wrapper{
      margin-top: $default-padding*3;
      @media screen and (min-width: 1024px){
        margin-top: $default-padding*4;
      }
      .app-btn{
        @include app-btn(140px, 50px, $pickled-bluewood, $pickled-bluewood);
      }
    }
  }
}