@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.single-question-view {
  padding: 30px 0px;
  .single-question-container {
    width: 60%;
    .error-message-view {
      margin: 15px 0px 0px;
      display: flex;
      justify-content: flex-end;
      font-weight: 800;
    }
    .question-header-one {
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #aeaeae;
      display: flex;
      justify-content: space-between;
      .heading-left {
        display: flex;
      }
      .heading-right {
        .print-link {
          margin: 0px;
          margin-top: 10px;
          float: left;
        }
      }
      .weightage {
        font-size: 16px;
        letter-spacing: -0.3px;
        text-align: right;
        color: #3d3d3d;
        cursor: pointer;
      }
      .answered-count {
        padding-left: 15px;
      }
    }
    .question-header-2 {
      display: flex;
      justify-content: space-between;
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
      padding-top: 60px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #4e266a;
    }
    .question-content {
      align-items: center;
      padding-top: 30px;
      .question-number {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        background-color: #b7caff;
        font-size: 30px;
        text-align: center;
        color: #ffffff;
        @media screen and (max-width: 1024px){
          width: 30px !important;
          height: 30px;
          font-size: 20px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
      .question {
        padding-left: 62px;
        font-size: 26px;
        @media screen and (max-width: 1024px){
          font-size: 20px;
        }  
        padding-top: 40px;
        line-height: 1.69;
        letter-spacing: normal;
        color: #3d3d3d;
      }
    }
    .read-more {
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
      padding-top: 30px;
      text-decoration: overline;
      font-size: 16px;
      letter-spacing: -0.3px;
      cursor: pointer;
    }
    .scoring-the-question {
      padding-top: 100px;
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
      display: flex;
      justify-content: space-between;
      .button {
        width: 240px;
        height: 50px;
      }
    }
    .question-description {
      padding-top: 50px;
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
    }
    .importance {
      display: flex;
      padding-left: 100px;
      @media screen and (max-width: 1024px){
        padding-left: 80px;
      }
      margin-top: 15px;
      color: #D3D3D3;
      .rise {
        padding-right: 20px;
        cursor: pointer;
      }
      .less-relevent {
        cursor: pointer;
      }
    }
    .questionnaie-footer {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .next-btn {
        width: 400px;
        height: 50px;
        margin-left: 100px;
        @media screen and (max-width: 1024px){
          margin-left: 73px;
        }
        margin-top: 50px;
        font-size: 15px;
        color: #ffffff;
      }
      .view-results {
        font-size: 16px;
        letter-spacing: -0.3px;
        text-align: right;
        color: #3d3d3d;
        @media screen and (max-width: 1024px){
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
}

.questionnaire-question-wrapper {
  .height-fill {
    height: -webkit-fill-available;
  }
}