@import "variables.scss";
@import "colors.scss";
@import "mixins.scss";

.different-category-model {
  .header {
    font-size: $default-font-size + 13 !important;
    letter-spacing: -0.4px;
    border-bottom: none !important;
    padding: $default-padding * 5 !important;
    padding-bottom: $default-padding * 4 !important;
    display: flex !important;
    justify-content: space-between;
    .close-icon {
      cursor: pointer;
    }
  }
  .content {
    padding-bottom: $default-padding * 5 !important;
    .category-item {
      display: flex;
      justify-content: space-between;
      height: 50px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #e7e7e7;
      align-items: center;
      padding: $default-padding + 5;
      margin: 10px 29px;
      cursor: pointer;
      .topics-answered {
        display: flex;
        justify-content: space-between;
        .done-icon {
          width: 20px;
        }
      }
    }
    .view-results {
      color: black;
      text-decoration: underline;
      margin: 28px 29px 10px;
    }
  }
}