@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/app-icons/icomoon.eot?4y4b7h');
  src:  url('../assets/fonts/app-icons/icomoon.eot?4y4b7h#iefix') format('embedded-opentype'),
    url('../assets/fonts/app-icons/icomoon.ttf?4y4b7h') format('truetype'),
    url('../assets/fonts/app-icons/icomoon.woff?4y4b7h') format('woff'),
    url('../assets/fonts/app-icons/icomoon.svg?4y4b7h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-icon:before {
  content: "\e900";
  color: #f5a623;
}
.icon-arrow-icon:before {
  content: "\e901";
  color: #4b2369;
}
.icon-done-icon .path1:before {
  content: "\e902";
  color: rgb(126, 211, 33);
}
.icon-done-icon .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(251, 251, 251);
}
.icon-done-icon .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-text:before {
  content: "\e905";
  color: #4b2369;
}
.icon-icn-analyze-8:before {
  content: "\e906";
  color: #7b618e;
}
.icon-icn-edit:before {
  content: "\e907";
  color: #aeaeae;
}
.icon-icn-importance:before {
  content: "\e908";
  color: #7b618e;
}
.icon-icn-report:before {
  content: "\e909";
  color: #7b618e;
}
.icon-icn-stakeholders:before {
  content: "\e90a";
  color: #7b618e;
}
.icon-icn-topics:before {
  content: "\e90b";
  color: #7b618e;
}
.icon-icn-value:before {
  content: "\e90c";
  color: #7b618e;
}
.icon-trash:before {
  content: "\e90d";
}